import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  items: [],
}

export const listLogs = createAsyncThunk('logs/list', async (_, thunkAPI) => {
  try {
    return await api('/logs', {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(listLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(listLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = [...action.payload]
      })
      .addCase(listLogs.rejected, (state, action) => {
        state.loading = false;
      })
  }
})

export default logsSlice.reducer
