import { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';

import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';

import config from '../config';
import productTemplates from '../shopify-buy-button/templates/product';
import { isCartEmpty, cartConfig } from '../shopify-buy-button/config/cart';
import { toggleConfig } from '../shopify-buy-button/config/toggle';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const Catalogue = ({ shopifyUI }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!shopifyUI) {
      return
    }

    let options = {
      product: {
        templates: {
          button: productTemplates.catalogueButton
        },
        order: [
          'img',
          'imgWithCarousel',
          'title',
          'variantTitle',
          'price',
          'options',
          'quantity',
          'description',
          'button',
          'buttonWithQuantity',
        ],
        classes: {
          productDetailsBtn: 'product-details-btn'
        },
        DOMEvents: {
          'click .product-details-btn': function(e, target) {
            let data = target.dataset

            let product = shopifyUI.components.collection[0].products.find(p => {
              return p.model.id == data.id
            })

            product.openModal()
          }
        },
        styles: {
          price: {
            'font-size': '18px',
            'font-weight': 'bold',
          },
          productDetailsBtn: {
            'margin': '20px auto 0',
            'font-weight': 'bold',
            'font-size': '14px',
            'padding': '12px 25px',
            'padding-top': '15px',
            'padding-bottom': '15px',
            'background-color': '#31a5e1',
            'border-radius': '5px',
            'color': '#fff',
            'letter-spacing': '.3px',
            'cursor': 'pointer',
            'transition': 'background 200ms ease',
            'max-width': '100%',
            'text-overflow': 'ellipsis',
            'overflow': 'hidden',
            'line-height': '1.2',
            'border': '0',
            '-webkit-transition': 'background 200ms ease',
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
            'text-align': 'center',
            ':hover': {
              'background-color': "#2B8FC2"
            },
          },
          imgWrapper: {
            'height': '300px',
            'display': 'flex',
            'justify-content': 'center',
            'align-items': 'center',
          },
          img: {
            'max-height': '100%',
          },
          title: {
            'text-overflow': 'ellipsis',
            'overflow': 'hidden',
            'display': '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            'height': '44px',
            'margin': '10px 0',
          },
          product: {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "20px",
              "margin-bottom": "50px",
              "width": "calc(25% - 20px)",
            },
            ":has(button:disabled)": {
              "display": "none"
            }
          },
          button: {
            "font-weight": "bold",
            "font-size": "14px",
            "padding-top": "15px",
            "padding-bottom": "15px",
            ":hover": {
              "background-color": "#2B8FC2"
            },
            "background-color": "#31a5e1",
            ":focus": {
              "background-color": "#2B8FC2"
            },
            "border-radius": "5px"
          },
        },
        contents: {
          options: false,
        },
      },
      productSet: {
        styles: {
          products: {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          },
        },
        text: {
          nextPageButton: 'SHOW MORE',
        },
      },
      modalProduct: {
        contents: {
          options: false,
          imgWithCarousel: true,
          img: false,
        },
      }
    }

    if (isCartEmpty(shopifyUI.components.cart)) {
      options.cart = cartConfig(shopifyUI)
      options.toggle = toggleConfig(shopifyUI)
    }

    shopifyUI.createComponent('collection', {
      id: config.collectionId,
      node: document.getElementById('collection-component'),
      moneyFormat: `${currency}%7B%7Bamount%7D%7D`,
      options,
    });

    setLoading(false)
  }, [shopifyUI])

  return (
    <>
      <TabHeader
        title="Product Catalogue"
        tab_id="bulk_catalogue"
      />
      {
        loading &&
          <Loader/>
      }
      <div id='collection-component'></div>
    </>
  )
}

export default Catalogue;
