export const toggleConfig = () => {
  return {
    styles: {
      toggle: {
        'background-color': '#7f35ff',
        ':hover': {
          'background-color': '#7f35ff',
        }
      }
    }
  }
}
