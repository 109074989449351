const quantityTemplate = `<div class="{{data.classes.product.quantity}} {{data.quantityClass}}" data-element="product.quantity">
            {{#data.contents.quantityDecrement}}
              <button class="{{data.classes.product.quantityButton}} {{data.classes.product.quantityDecrement}}" type="button" data-element="product.quantityDecrement"><span>-</span><span class="visuallyhidden">Decrement</span></button>
            {{/data.contents.quantityDecrement}}
            {{#data.contents.quantityInput}}
              <input class="{{data.classes.product.quantityInput}}" type="number" min="0" aria-label="Quantity" value="{{data.selectedQuantity}}" data-element="product.quantityInput">
            {{/data.contents.quantityInput}}
            {{#data.contents.quantityIncrement}}
              <button class="{{data.classes.product.quantityButton}} {{data.classes.product.quantityIncrement}}" type="button" data-element="product.quantityIncrement"><span>+</span><span class="visuallyhidden">Increment</span></button>
            {{/data.contents.quantityIncrement}}
           </div>`;
const buttonTemplate = '<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper"><button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">{{data.buttonText}}</button></div>';

const templates = {
  catalogueButton: `
<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
  <button class="product-details-btn" data-id="{{data.id}}">
    PRODUCT DETAILS
  </button>
  <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">
    {{data.buttonText}}
  </button>
</div>
`,
  redeemButton: `
<div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper">
  <button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="product-redeem-btn" data-id="{{data.id}}">
  REDEEM
  </button>
</div>
  `,
  redeemButtonWithQuantity: `
<div class="{{data.classes.product.buttonWithQuantity}}" data-element="product.buttonWithQuantity">
  {{#data.redeemLimit}}
  <p class="{{data.classes.product.redeemLimitText}}">You may redeem up to {{data.redeemLimit}} units</p>
  {{/data.redeemLimit}}
  ${quantityTemplate}${buttonTemplate}
</div>
  `,
  description: `
<div class="{{data.classes.product.description}}" data-element="product.description">{{{data.totalInventory}}}</div>
  `
}

export default templates
