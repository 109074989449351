import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import MobileDetect from 'mobile-detect';

import Dashboard from './Dashboard';
import Logout from './Logout';
import Users from './Users';
import OrderDetail from './OrderDetail';
import Orders from './Orders';
import Credits from './Credits';
import Redemption from './Redemption';
import Invoice from './Invoice';
import Receipt from './Receipt';
import Catalogue from './Catalogue';
import Profile from './Profile';
import ManualOrderDetail from './ManualOrderDetail';
import RequestCredits from './RequestCredits';
import Rewards from './Rewards';
import Logs from './Logs';
import NotFound from '../components/NotFound';
import GFBAlert from '../components/GFBAlert';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Forbidden from '../components/Forbidden';
import RedemptionHistory from './RedemptionHistory';
import Shipping from './Shipping';
import Refund from './Refund';

import { resetError } from '../redux/slices/error';
import { fadeTransition } from '../styles/transitions';
import { listQuickHelps } from '../redux/slices/quickHelp';
import config from '../config';
import { cartConfig } from '../shopify-buy-button/config/cart';
import { toggleConfig } from '../shopify-buy-button/config/toggle';

const localStorageKey = `${config.storefrontAccessToken}.${config.shopifyDomain}.checkoutId`
const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const AdminRoute = ({ user, children }) => {
  return (
    <>
      {
        user && user.role === 'admin' ? (
          children
        ) : (
          <Navigate to="/403" />
        )
      }
    </>
  )
}

const App = () => {
  const [shopifyBuyClient, setShopifyBuyClient] = useState(null);
  const [shopifyUI, setShopifyUI] = useState(null);
  const { user } = useSelector(state => state.auth);
  const { error, errorMsg } = useSelector(state => state.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!user){
      if (shopifyUI) {
        shopifyUI.components.cart.forEach(cart => {
          cart.toggles[0].destroy()
        })
        shopifyUI.components.cart = []
      }
      window.ShopifyBuy = null;
      return navigate('/login')
    }

    if (shopifyUI) {
      if (user.last_checkout_id) {
        localStorage.setItem(localStorageKey, user.last_checkout_id)
      }

      initCart()
    }
  }, [user, navigate, shopifyUI])

  useEffect(() => {
    // dispatch(listQuickHelps());

    if (window.ShopifyBuy && !shopifyBuyClient || !shopifyUI) {
      initClient();
    }
  }, [])

  const initClient = () => {
    var client = window.ShopifyBuy.buildClient({
      domain: config.shopifyDomain,
      storefrontAccessToken: config.storefrontAccessToken,
    });

    // const newTypeBundle = JSON.parse(JSON.stringify(client.graphQLClient.typeBundle));
    // newTypeBundle.types.Product.fieldBaseTypes.tags = "String"
    // client.graphQLClient.typeBundle = newTypeBundle

    setShopifyBuyClient(client);

    let ui = window.ShopifyBuy.UI.init(client)

    setShopifyUI(ui)
  }

  const initCart = () => {
    shopifyUI.createComponent('cart', {
      moneyFormat: `${currency}%7B%7Bamount%7D%7D`,
      options: {
        cart: cartConfig(shopifyUI),
        toggle: toggleConfig(),
      }
    })
  }

  const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;

  return (
    <>
      <Transition in={error} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="danger" alert={errorMsg} handleClose={() => {
              if(error){
                dispatch(resetError())
              }
            }} />
        )}
      </Transition>
      <Header/>
      <div className="row">
        <div className="d-none d-md-flex justify-content-between flex-column position-fixed sidebar">
          <Sidebar />
          <p className="text-center mb-0 text-white">Powered by Giftr</p>
        </div>
        <div className="main">
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/orders" element={<Orders shopifyUI={shopifyUI} />}/>
            <Route path="/orders/:orderId" element={<OrderDetail />} />
            <Route path="/manual_orders/:orderId" element={<ManualOrderDetail />} />
            <Route path="/credits" element={<Credits/>}/>
            <Route path="/redemption" element={<Redemption shopifyUI={shopifyUI} shopifyClient={shopifyBuyClient}/>}/>
            <Route path="/redemption_history" element={<RedemptionHistory shopifyClient={shopifyBuyClient}/>}/>
            <Route path="/request_credits" element={<RequestCredits/>}/>
            <Route path="/catalogue" element={<Catalogue shopifyUI={shopifyUI} />}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/shipping_delivery" element={<Shipping/>}/>
            <Route path="/refund_warranty" element={<Refund/>}/>
            <Route path="/rewards" element={<Rewards/>}/>
            <Route path="/users" element={<AdminRoute user={user} children={<Users/>}/>}/>
            <Route path="/logs" element={<AdminRoute user={user} children={<Logs/>}/>}/>
            <Route path="/invoice" element={<Invoice/>}/>
            <Route path="/receipt" element={<Receipt/>}/>
            <Route path="/logout" element={<Logout />}/>
            <Route path="/403" element={<Forbidden/>} />
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </div>
      </div>
    </>
  )
}

export default App;
