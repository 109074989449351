import { Nav, Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/coway-white-logo.png';
import { useSelector } from 'react-redux';

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

const Sidebar = () => {
  const { pathname } = useLocation();
  const { user } = useSelector(state => state.auth)

  return (
    <Nav className="nav-sidebar flex-column mb-0">
      <Nav.Item className="d-flex flex-column align-items-center px-3 mw-100">
        <div>
          <img src={logo} width="140" className="mx-auto" style={{margin:'25px 0 10px 0'}}/>
        </div>
        <p className="text-center" style={{color: '#fff'}}>Gifts & Rewards Center</p>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/dashboard'
        ><FontAwesomeIcon icon={solid('house')}/>
          <span className="px-2">Dashboard</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/catalogue') && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/catalogue'
        ><FontAwesomeIcon icon={solid('layer-group')}/>
          <span className="px-2">Order Now</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/orders') && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/orders'
        ><FontAwesomeIcon icon={solid('clipboard')}/>
          <span className="px-2">Order History</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Accordion className="nav-accordion" defaultActiveKey="0">
          <Accordion.Item style={{border:'none'}} eventKey="0">
            <Accordion.Header style={{color: '#31a5e1'}}>
              <div style={{gridTemplateColumns: '30px 1fr'}} className="d-grid accordion-button-container">
                <FontAwesomeIcon icon={solid('gift')}/>
                <span className="px-2">Point Redemption</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Link
                className={`${isActiveRoute(pathname, '/redemption') && 'active'} d-grid`}
                as={Link}
                to="/redemption"
                style={{gridTemplateColumns: '30px 1fr'}}
              >
                <div />
                <span className="px-2">Redeem Now</span>
              </Nav.Link>
              <Nav.Link
                className={`${isActiveRoute(pathname, '/redemption_history') && 'active'} d-grid`}
                as={Link}
                to="/redemption_history"
                style={{gridTemplateColumns: '30px 1fr'}}
              >
                <div />
                <span className="px-2">Redemption History</span>
              </Nav.Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/shipping_delivery') && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/shipping_delivery'
        ><FontAwesomeIcon icon={solid('truck')}/>
          <span className="px-2">Shipping & Delivery</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/refund_warranty') && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/refund_warranty'
        ><FontAwesomeIcon icon={solid('money-bill')}/>
          <span className="px-2">Refund & Warranty Claims</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/profile') && 'active'} d-grid`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/profile'
        ><FontAwesomeIcon icon={solid('user')}/>
          <span className="px-2">User Profile</span>
        </Nav.Link>
      </Nav.Item>
      {
        user && user.role === 'admin' &&
          <Nav.Item>
            <Accordion className="nav-accordion" defaultActiveKey="0">
              <Accordion.Item style={{border:'none'}} eventKey="0">
                <Accordion.Header style={{color: '#31a5e1'}}>
                <div style={{gridTemplateColumns: '30px 1fr'}} className="d-grid accordion-button-container">
                    <FontAwesomeIcon icon={solid('wrench')}/>
                    <span className="px-2">Admin Tools</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/users') && 'active'} d-grid`}
                    as={Link}
                    to="/users"
                    style={{gridTemplateColumns: '30px 1fr'}}
                  >
                    <div />
                    <span className="px-2">Users & Rewards</span>
                  </Nav.Link>
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/logs') && 'active'} d-grid`}
                    as={Link}
                    to="/logs"
                    style={{gridTemplateColumns: '30px 1fr'}}
                  >
                    <div />
                    <span className="px-2">Activity Log</span>
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Nav.Item>
      }
      <Nav.Item>
        <Nav.Link
          className={`${isActiveRoute(pathname, '/logout') && 'active'} d-grid logout-btn`}
          style={{gridTemplateColumns: '30px 1fr'}}
          as={Link}
          to='/logout'
        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="flip-h"/>
          <span className="px-2">Log Out</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export default Sidebar;
