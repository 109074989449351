import { Navbar, Nav, Offcanvas, Container, Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import logo from '../images/coway-white-logo.png';

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

const Header = () => {
  const { user } = useSelector(state => state.auth);

  const [headerState, setHeaderState] = useState({
    expanded: false,
    navExpanded: false,
    announcements: []
  })

  const { pathname } = useLocation();

  const handleToggleClose = () => {
    const { expanded } = headerState
    expanded ?
      setHeaderState({
        ...headerState,
        expanded: false
      })
      :
      setHeaderState({
        ...headerState,
        expanded: true
      })
  }

  return (
    <>
      <Navbar className="header py-0" bg="light" fixed="top" expand={false} style={{width: '100%'}}>
        <Container className="d-print-none d-lg-none mx-2" fluid>
          <Navbar.Brand href="/">
            <img src={logo} alt="coway" className="header-branding align-text-bottom"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => handleToggleClose()}/>
          <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end" onHide={() => handleToggleClose()} show={headerState.expanded}>
            <Offcanvas.Body className="header-offcanvas">
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'} d-grid`}
                  as={Link}
                  to='/dashboard' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('house')} style={{placeSelf: 'center'}}/>Dashboard</Nav.Link>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/catalogue')) && 'active'} d-grid`}
                  as={Link}
                  to='/catalogue' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('layer-group')} style={{placeSelf: 'center'}}/>Order Now</Nav.Link>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/orders')) && 'active'} d-grid`}
                  as={Link}
                  to='/orders' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('clipboard')} style={{placeSelf: 'center'}}/>Order History</Nav.Link>
                <Accordion className="nav-accordion" defaultActiveKey="0">
                  <Accordion.Item style={{border:'none'}} eventKey="0">
                    <Accordion.Header>
                    <div style={{gridTemplateColumns: '40px 1fr'}} className="d-grid accordion-button-container">
                        <FontAwesomeIcon icon={solid('gift')} style={{placeSelf: 'center'}}/>
                        Point Redemption
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/redemption') && 'active'} d-grid`}
                        as={Link}
                        to="/redemption"
                        style={{gridTemplateColumns: '40px 1fr'}}
                        onClick={() => handleToggleClose()}
                      >
                        <div />
                        Redeem Now
                      </Nav.Link>
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/redemption_history') && 'active'} d-grid`}
                        as={Link}
                        to="/redemption_history"
                        style={{gridTemplateColumns: '40px 1fr'}}
                        onClick={() => handleToggleClose()}
                      >
                        <div />
                        Redemption History
                      </Nav.Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/shipping_delivery')) && 'active'} d-grid`}
                  as={Link}
                  to='/shipping_delivery' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('truck')} style={{placeSelf: 'center'}}/>Shipping & Delivery</Nav.Link>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/refund_warranty')) && 'active'} d-grid`}
                  as={Link}
                  to='/refund_warranty' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('money-bill')} style={{placeSelf: 'center'}}/>Refund & Warranty Claims</Nav.Link>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/profile')) && 'active'} d-grid`}
                  as={Link}
                  to='/profile' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr'}}
                ><FontAwesomeIcon icon={solid('user')} style={{placeSelf: 'center'}}/>User Profile</Nav.Link>
                {
                  user && user.role === 'admin' &&
                    <Accordion className="nav-accordion" defaultActiveKey="0">
                      <Accordion.Item style={{border:'none'}} eventKey="0">
                        <Accordion.Header>
                          <div style={{gridTemplateColumns: '40px 1fr'}} className="d-grid accordion-button-container">
                            <FontAwesomeIcon icon={solid('wrench')} style={{placeSelf: 'center'}}/>
                            Admin Tools
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Nav.Link
                            className={`${isActiveRoute(pathname, '/users') && 'active'} d-grid`}
                            as={Link}
                            to="/users"
                            style={{gridTemplateColumns: '40px 1fr'}}
                            onClick={() => handleToggleClose()}
                          >
                            <div />
                            Users & Rewards
                          </Nav.Link>
                          <Nav.Link
                            className={`${isActiveRoute(pathname, '/logs') && 'active'} d-grid`}
                            as={Link}
                            to="/logs"
                            style={{gridTemplateColumns: '40px 1fr'}}
                            onClick={() => handleToggleClose()}
                          >
                            <div />
                            Activity Log
                          </Nav.Link>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                }
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/logout')) && 'active'} d-grid`}
                  as={Link}
                  to='/logout' onClick={() => handleToggleClose()}
                  style={{gridTemplateColumns: '40px 1fr', color: '#D15D5D'}}
                ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} style={{placeSelf: 'center'}} transform="flip-h"/>Log Out</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
