import React from 'react';
import { Alert } from 'react-bootstrap';

const GFBAlert = ({ timeout, handleClose, alert, variant, dismissible = true, ...restProps }) => {

  const onClose = () => {

    if(handleClose){
      handleClose()
    }
  }

  setTimeout(() => {
    if(handleClose){
      handleClose()
    }
  }, timeout || 10000)

  return (
    <Alert onClose={onClose} className='position-fixed translate-middle-x gfb-alert' variant={variant} {...restProps} dismissible={dismissible}>
      {
        Array.isArray(alert) ?
          <ul>
            {
              alert.map((alert, i) => (
                <li key={i}>
                  {alert.msg}
                </li>
              ))
            }
          </ul>
          :
          alert
      }
    </Alert>
  )
}

export default GFBAlert;
