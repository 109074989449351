import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import { useState, useEffect } from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import _ from 'lodash';
import Clipboard from 'clipboard';
import { useSelector } from 'react-redux';

import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';
import api from '../helpers/apiClient';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const RedemptionHistory = ({ shopifyClient }) => {
  const { user } = useSelector(state => state.auth)

  const [loading, setLoading] = useState(false)
  const [discountCodes, setDiscountCodes] = useState([])

  useEffect(() => {
    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {
      e.trigger.innerText = 'COPIED'
      e.clearSelection();
      setTimeout(() => {
        e.trigger.innerText = 'COPY'
      }, 5000)
    });
  }, [])

  useEffect(() => {
    if (!shopifyClient) {
      return
    }

    if (discountCodes.length > 0) {
      return
    }

    getDiscountCodes()
  }, [discountCodes, shopifyClient])

  const getDiscountCodes = () => {
    if (loading) {
      return
    }

    setLoading(true)
    return api('/discount/list').then(async (result) => {
      for (let i = 0; i < result.length; i++) {
        if (!result[i].product_id) {
          continue
        }
        result[i].product = await shopifyClient.product.fetch(result[i].product_id)
      }
      setDiscountCodes(result)
      setLoading(false)
    })
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  const renderCode = (col) => {
    return (
      <div className="position-relative" style={{paddingRight: '100px'}}>
        {col}
        <Button onClick={e => e.stopPropagation()} className="copy-to-clipboard py-1 position-absolute" style={{right: '0', width: '80px', marginRight: '20px'}} variant="secondary" data-clipboard-text={col}>COPY</Button>
      </div>
    )
  }

  const renderDate = (col) => {
    if(col){
      return moment(col).format('DD/MM/YYYY')
    }
  }

  const renderGiftcard = (row) => {

    const titleStyle = {
      "fontSize": "1.2em",
      "fontWeight": "300",
      "fontFamily": "Varela Round, Arial, sans-serif",
    }

    return (
      <div className="container-fluid" style={{color: '#5A5A5A'}}>
        <Timeline lineStyle={{background: 'none'}}>
          {
            _.map(_.reverse(row.timeline), event => {
              const { subject, order_name, order_id, amount } = event

              return (
                <TimelineEvent
                  key={event.created}
                  createdAt={moment(event.created).format('DD/MM h:mm a')}
                  titleStyle={titleStyle}
                  icon={<FontAwesomeIcon icon={solid('calendar')} transform="grow-4" style={{color: '#31a5e1'}}/>}
                  bubbleStyle={{background: 'none', border: 'none'}}
                >
                  <div className="buyer-speech-bubble" style={{whiteSpace: 'pre-line'}}>
                    {
                      subject === 'creation' &&
                        'A new gift card was issued.'
                    }
                    {
                      subject === 'transaction' &&
                        <span>
                          Paid {currency}{amount.toFixed(2)} with this gift card on order{" "}
                          <a href={`http://business.giftr.my/orders/${order_id}`}>{order_name}</a>
                        </span>
                    }
                  </div>
                </TimelineEvent>
              )
            })
          }
      </Timeline>
      </div>
    )
  }

  const renderProduct = (col) => {
    return col ? col.title : ''
  }

  const columns = [
    {
      dataField: 'giftcard_id',
      text: '',
      formatter: () => {
        return <Button variant="info" size="sm" style={{borderRadius: '100%'}}>
          <FontAwesomeIcon icon={solid('chevron-down')} transform="grow-4" style={{color: 'white'}}/>
          </Button>
      },
      headerStyle: {
        width: '36px',
      }
    },
    { dataField: 'created_at', text: 'Created', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
    { dataField: 'code', text: 'Discount Code', formatter: renderCode, headerStyle: { width: '255px', } },
    { dataField: 'product', text: 'Product', formatter: renderProduct, headerStyle: { width: '110px', } },
    { dataField: 'coway_id', text: 'Coway ID', sort: true, hidden: user.role !== 'admin', headerStyle: { width: '110px', } },
    { dataField: 'value', text: 'Value', formatter: (col) => { return `${currency} ${col}` }, headerStyle: { width: '110px', } },
    { dataField: 'ends_at', text: 'Expiry', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
  ];

  return (
    <>
      <TabHeader
        title="Redemption History"
        tab_id="redemption_history"
      />
      <h4>Flexi Point Codes <span style={{fontSize: '0.6em', color: '#5a5a5a'}}>Click on
        [<FontAwesomeIcon icon={solid('chevron-down')} />]
        sign to view the order history</span></h4>
      { loading &&
      <Loader />
      }
      { !loading &&
        <ToolkitProvider
          keyField='code'
          data={discountCodes}
          columns={columns}
        >
          {
            props => (
              <>
                <BootstrapTable
                  { ...props.baseProps }
                  bordered={ false }
                  pagination={discountCodes.length > 10 ? paginationFactory({
                    showTotal: true,
                    sizePerPage: 10,
                    sizePerPageList: [{
                      text: '10', value: 10
                    }, {
                      text: '25', value: 25
                    }, {
                      text: '50', value: 50
                    }, {
                      text: 'All', value: discountCodes.length
                    }],
                  }) : undefined}
                  hover
                  noDataIndication={() => 'There is no data to display'}
                  expandRow={{
                    renderer: renderGiftcard
                  }}
                  wrapperClasses="mb-3"
                  rowClasses="cursor-pointer"
                  defaultSorted={[{
                    dataField: 'created_at',
                      order: 'desc',
                  }]}
                />
              </>
            )
          }
        </ToolkitProvider>
      }
    </>
  )
}

export default RedemptionHistory
