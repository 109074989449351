import { Card, Row, Col, Button, Badge, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import RangeSlider from 'react-bootstrap-range-slider';
import { Transition } from 'react-transition-group';

import Loader from '../components/Loader';
import GFBAlert from '../components/GFBAlert';
import ConfirmModal from '../components/ConfirmModal';
import TabHeader from '../components/TabHeader';
import api from '../helpers/apiClient';
import { getProfile } from '../redux/slices/user';
import { addError } from '../redux/slices/error';
import denominations from '../data/creditsDenom.json';
import creditsLogo from '../images/credits-logo.svg';
import { fadeTransition } from '../styles/transitions';
import PointsDetailModal from '../components/PointsDetailModal';
import config from '../config';
import productTemplates from '../shopify-buy-button/templates/product';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'
const localStorageKey = `${config.storefrontAccessToken}.${config.shopifyDomain}.checkoutId`

const Redemption = ({ shopifyUI, shopifyClient }) => {
  const { item: profile, loading, loaded } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [selectedDenom, setSelectedDenom] = useState({ value: null, amount: '' })
  const [balance, setBalance] = useState(0)
  const [showConfirmRedeem, setShowConfirmRedeem] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [redeemSuccess, setRedeemSuccess] = useState(false)
  const [showPointsDetail, setShowPointsDetail] = useState(false)
  const [redeemCollection, setRedeemCollection] = useState(null)
  const [redeemGift, setRedeemGift] = useState(null)
  const [showConfirmRedeemGift, setShowConfirmRedeemGift] = useState(false)

  useEffect(() => {
    dispatch(getProfile())
  }, [])

  useEffect(() => {
    if (!shopifyClient || !loaded) {
      return
    }

    shopifyClient.collection.fetchWithProducts(`gid://shopify/Collection/${config.collectionId}`, { productsFirst: 100 }).then(collection => {
      setRedeemCollection(collection)
    })
  }, [shopifyClient, loaded])

  useEffect(() => {
    if (profile && profile.points) {
      setBalance(_.reduce(profile.points, (sum, point) => { return sum + point.balance }, 0))
    }
  }, [profile])

  const onChangeSlider = (value) => {
    setSliderValue(value)
    setSelectedDenom({ value: null, amount: '' })
  }

  const handleRedeemDiscountCode = async () => {
    return api('/redeem/discount_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: profile.id,
        value: sliderValue
      })
    }).then(() => {
      dispatch(getProfile())
      setRedeemSuccess(true)
    }).finally(() => {
      setShowConfirmRedeem(false)
    })
  }

  const renderImage = (col) => {
    let image = col[0]
    return (
      <img src={image.src} alt={image.altText} style={{height: '70px'}} />
    )
  }

  const renderPrice = (col, row) => {
    let variant = row.variants[0]

    return `${currency} ${parseFloat(variant.price.amount).toFixed(2)}`
  }

  const renderPoints = (col, row) => {
    let variant = row.variants[0]

    let price = parseFloat(variant.price.amount)

    return price * 100
  }

  const handleRedeem = (e, row) => {
    e.stopPropagation();
    setShowConfirmRedeemGift(true)
    setRedeemGift(row)
  }

  const handleRedeemGift = async () => {
    return api('/redeem/discount_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: profile.id,
        value: redeemGift.variants[0].price.amount * 100,
        redeem_gift: true,
        product_id: redeemGift.id,
      })
    }).then((result) => {
      const checkoutId = localStorage.getItem(localStorageKey)
      const variantId = redeemGift.variants[0].id

      return shopifyUI.client.checkout.addLineItems(checkoutId, [{
        variantId: variantId,
        quantity: 1,
        customAttributes: {
          key: 'redeemed',
          value: 'true'
        }
      }]).then((checkout) => {
        shopifyUI.components.cart[0].model = checkout;
        shopifyUI.components.cart[0].updateCache(shopifyUI.components.cart[0].model.lineItems);
        shopifyUI.components.cart[0].view.render();
        shopifyUI.components.cart[0].toggles.forEach((toggle) => toggle.view.render());
      }).then(() => {
        shopifyUI.client.checkout.addDiscount(shopifyUI.components.cart[0].model.id, result.code).then((checkout) => {
          shopifyUI.components.cart[0].model = checkout;
          shopifyUI.components.cart[0].updateCache(shopifyUI.components.cart[0].model.lineItems);
          shopifyUI.components.cart[0].view.render();
          shopifyUI.components.cart[0].toggles.forEach((toggle) => toggle.view.render());
          shopifyUI.components.cart[0].open()
        })
      }).finally(() => {
        dispatch(getProfile())
      })
    }).finally(() => {
      setShowConfirmRedeemGift(false)
    })
  }

  const renderRedeem = (col, row) => {
    return (
      <Button onClick={(e) => handleRedeem(e, row)} variant="primary">Redeem</Button>
    )
  }

  const showProductPopup = (id) => {
    const foundProduct = _.find(shopifyUI.components.product, product => product.id === parseInt(id))

    if (foundProduct) {
      foundProduct.openModal()
      return
    }

    shopifyUI.createComponent('product', {
      id: parseInt(id),
      moneyFormat: `${currency}%7B%7Bamount%7D%7D`,
      node: document.getElementById('product-component'),
      options: {
        product: {
          events: {
            afterInit: (product) => {
              product.openModal()
            }
          },
          classes: {
            productRedeemBtn: 'product-redeem-btn'
          },
          styles: {
            productRedeemBtn: {
              'margin': '20px auto 0',
              'font-weight': 'bold',
              'font-size': '14px',
              'padding': '12px 25px',
              'padding-top': '15px',
              'padding-bottom': '15px',
              'background-color': '#31a5e1',
              'border-radius': '5px',
              'color': '#fff',
              'letter-spacing': '.3px',
              'cursor': 'pointer',
              'transition': 'background 200ms ease',
              'max-width': '100%',
              'text-overflow': 'ellipsis',
              'overflow': 'hidden',
              'line-height': '1.2',
              'border': '0',
              '-webkit-transition': 'background 200ms ease',
              '-moz-appearance': 'none',
              '-webkit-appearance': 'none',
              'text-align': 'center',
              ':hover': {
                'background-color': "#2B8FC2"
              },
            },
          },
        },
        modalProduct: {
          templates: {
            buttonWithQuantity: productTemplates.redeemButtonWithQuantity,
            button: productTemplates.redeemButton,
          },
          contents: {
            options: false,
            imgWithCarousel: true,
            img: false,
            buttonWithQuantity: false,
            button: true,
          },
          classes: {
            redeemLimitText: 'redeem-limit'
          },
          classes: {
            productRedeemBtn: 'product-redeem-btn'
          },
          DOMEvents: {
            'click .product-redeem-btn': function(e, target) {
              let data = target.dataset

              let product = shopifyUI.components.product.find(p => {
                return p.model.id == data.id
              })

              product.modal.close()
              setShowConfirmRedeemGift(true)
              setRedeemGift(product.model)
            }
          },
          styles: {
            productRedeemBtn: {
              'font-size': '14px',
              'padding': '12px 40px',
              'background-color': '#31a5e1',
              'border-radius': '5px',
              'color': '#fff',
              'letter-spacing': '.3px',
              'cursor': 'pointer',
              'transition': 'background 200ms ease',
              'max-width': '100%',
              'text-overflow': 'ellipsis',
              'overflow': 'hidden',
              'line-height': '1.2',
              'border': '0',
              '-webkit-transition': 'background 200ms ease',
              '-moz-appearance': 'none',
              '-webkit-appearance': 'none',
              'text-align': 'center',
              ':hover': {
                'background-color': "#2B8FC2"
              },
            },
          },
        }
      }
    })
  }

  const renderTitle = (col, row) => {
    return (
      <>
        <span>{row.title}</span>
        <FontAwesomeIcon
          icon={solid('info-circle')}
          style={{ color: '#31A5E1', marginLeft: '5px' }}
          onClick={(e) => {
            e.stopPropagation()
            let id = row.id.match(/\d*$/)[0]
            showProductPopup(id)
          }}
        />
      </>
    )
  }

  const redeemColumns = [
    { dataField: 'id', text: '', hidden: true },
    { dataField: 'images', text: 'Image', formatter: renderImage, headerStyle: { width: '50px', } },
    { dataField: 'title', text: 'Title', formatter: renderTitle, headerStyle: { width: '100px', } },
    { dataField: 'price', text: 'Price', formatter: renderPrice, headerStyle: { width: '50px', } },
    { dataField: 'points', text: 'Points', formatter: renderPoints, headerStyle: { width: '50px', } },
    { dataField: 'redeem', text: 'Redeem', formatter: renderRedeem, headerStyle: { width: '50px', } },
  ]

  const sufficientPoints = useMemo(() => {
    if (!redeemGift) {
      return false
    }

    return balance >= redeemGift.variants[0].price.amount * 100
  }, [redeemGift])

  return (
    <>
      <Transition in={redeemSuccess} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                Redeemed Flexi Point Code.
              </>
            } handleClose={() => {
              if (redeemSuccess) {
                setRedeemSuccess(false)
              }
            }} />
        )}
      </Transition>
      <TabHeader
        title="Redemption"
        tab_id="redemption"
      />
      { loading &&
        <Loader />
      }
      { !loading &&
        <>
          <Row className="mb-3">
            <Col lg={4}>
              <Card className="curve-border shadow-sm mb-3">
                <Card.Body className="p-4">
                  <h4 className="d-flex justify-content-between">
                    <span>Points</span>
                    <FontAwesomeIcon icon={solid('info-circle')} style={{fontSize: '22px'}} className="cursor-pointer" onClick={() => setShowPointsDetail(true)}/>
                  </h4>
                  <Row className="my-5">
                    <Col sm={12} className="text-center">
                      <p className="h3"><FontAwesomeIcon icon={solid('coins')} /> {balance}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <Card className="curve-border shadow-sm mb-3">
                <Card.Body className="p-4">
                  <h3 className="p-2">Redeem Flexi Point Codes</h3>
                  <div>
                    <div className="d-flex gap-2 px-2">
                      <img src={creditsLogo} height="100" style={{maxWidth: '100%'}} className="float-sm-end"/>
                      <ul>
                        <li>To be used in Giftr checkout</li>
                        <li>100 points = {currency} 1</li>
                        <li>Redeemed discount codes expire in 3 months</li>
                        <li>Redeemed discount codes cannot be refunded as points</li>
                      </ul>
                    </div>
                    <Row className="mt-2">
                      <Col sm={9} className="px-3 py-2">
                        <Row>
                          {
                            denominations.map(({ value, name, key }) => {
                              const size = (name === '1000' || name === '2000') ? 6 : 3

                              return (
                                <Col key={key} className='my-1' sm={size}>
                                  <Button
                                    variant="light"
                                    className={`${selectedDenom.value === value ? 'denom-active': ''} w-100 position-relative`}
                                    style={{border: '1px solid #dfdfdf'}}
                                    disabled={value > balance}
                                    onClick={() => {
                                      setSelectedDenom({
                                        value,
                                        amount: name
                                      })
                                      setSliderValue(value)
                                    }}
                                  >
                                    <span className="me-2 d-inline-block">{currency}{name}</span>
                                  </Button>
                                </Col>
                              )
                            })
                          }
                        </Row>
                        <p className="text-center my-2">OR</p>
                        <Form.Group as={Row}>
                          <Col xs={9}>
                            <RangeSlider value={sliderValue} onChange={e => onChangeSlider(e.target.value)} min={0} max={balance} variant="info" step={100} tooltipPlacement="top"/>
                          </Col>
                          <Col xs={3} className="d-flex align-items-center gap-2">
                            {currency}<Form.Control type="number" step="1" value={(sliderValue / 100).toFixed(2)} onChange={e => onChangeSlider(Math.floor(e.target.value * 100))}/>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={3} className="py-2 px-3 text-center d-flex align-items-center" style={{fontSize: '1.2rem'}}>
                        <Button className="btn-cta w-100" style={{border: '1px solid #dfdfdf', fontSize: '1.2rem'}}
                          onClick={() => {
                            if (sliderValue === 0) {
                              dispatch(addError('Please select amount of points to redeem'))
                              return
                            }
                            setShowConfirmRedeem(true)
                          }}
                        >
                          Redeem{sliderValue > 0 ? ` ${sliderValue} points` : ''}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          { redeemCollection &&
          <Card className="curve-border shadow-sm mb-3">
            <Card.Body className="p-4">
              <h3>Redeem Flexi Point Gifts</h3>
              <ul>
                <li>Discount code is generated for each redeemed gift and is auto applied at checkout</li>
              </ul>
              <ToolkitProvider
                keyField='id'
                data={redeemCollection.products}
                columns={redeemColumns}
              >
                {
                  props => (
                    <>
                      <BootstrapTable
                        { ...props.baseProps }
                        bordered={ false }
                        pagination={redeemCollection.products.length > 10 ? paginationFactory({
                          showTotal: true,
                          sizePerPage: 10,
                          sizePerPageList: [{
                            text: '10', value: 10
                          }, {
                            text: '25', value: 25
                          }, {
                            text: '50', value: 50
                          }, {
                            text: 'All', value: redeemCollection.products.length
                          }],
                        }) : undefined}
                        hover
                        noDataIndication={() => 'There is no data to display'}
                        wrapperClasses="mb-3"
                        rowClasses="cursor-pointer"
                        defaultSorted={[{
                          dataField: 'createdAt',
                            order: 'desc',
                        }]}
                      />
                    </>
                  )
                }
              </ToolkitProvider>
            </Card.Body>
          </Card>
          }
        </>
      }

      <div id="product-component" className="d-none" />

      <ConfirmModal show={showConfirmRedeem} onClose={() => setShowConfirmRedeem(false)} title="Redeem Flexi Point Code" onClickYes={handleRedeemDiscountCode}>
        <p>Confirm redeem Giftr Discount Code worth {currency}{(sliderValue/100).toFixed(2)} ({sliderValue} points)?</p>
      </ConfirmModal>
      <PointsDetailModal
        show={showPointsDetail}
        onHide={() => setShowPointsDetail(false)}
        points={profile && profile.points}
        history={profile && profile.logs && _.filter(profile.logs, log => log.type.includes('points'))}
      />
      <ConfirmModal show={showConfirmRedeemGift} onClose={() => setShowConfirmRedeemGift(false)} title="Redeem Flexi Point Gift" onClickYes={sufficientPoints && handleRedeemGift} noBtnTitle={sufficientPoints ? 'No': 'OK'}>
        { sufficientPoints &&
        <>
          <p>Confirm redeem {redeemGift.title}?</p>
          <p>{redeemGift.variants[0].price.amount * 100} points will be deducted & Flexi Point Code worth {currency}{parseFloat(redeemGift.variants[0].price.amount).toFixed(2)} will be created.</p>
          <p>You will have {balance - (redeemGift.variants[0].price.amount * 100)} points left.</p>
        </>
        }
        { !sufficientPoints &&
          <p>You do not have enough points to redeem this item.</p>
        }
      </ConfirmModal>
    </>
  )
}

export default Redemption;
