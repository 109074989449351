import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const NotFound = () => {
  return (
    <div className="jumbotron">
      <h1>
      <FontAwesomeIcon icon={regular('frown')} style={{marginRight: '12px'}}/>404 Not Found</h1>
      <p className="lead">We couldn't find what you're looking for</p>
        <p>
          <Link to="/" className="btn btn-primary btn-lg">Take Me To The Homepage</Link>
        </p>
    </div>
  )
}

export default NotFound;

