import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  items: [],
}

export const listUsers = createAsyncThunk('users/list', async (_, thunkAPI) => {
  try {
    return await api('/users', {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = [...action.payload.users]
        state.lastUpdated = action.payload.receivedAt
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.loading = false;
      })
  }
})

export default usersSlice.reducer
