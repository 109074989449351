import React from 'react';
import { Link } from 'react-router-dom';

const Forbidden = () => {
  return (
    <div className="jumbotron">
      <h1><i className="fa fa-ban red"></i> 403 Forbidden</h1>
      <p className="lead">Sorry! You don't have access permissions for that page</p>
        <p>
          <Link to="/" className="btn btn-primary btn-lg">Take Me To The Homepage</Link>
        </p>
    </div>
  )
}

export default Forbidden;
