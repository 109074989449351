import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './slices';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  whitelist: ['auth']
}, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store
