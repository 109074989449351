import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import generator from 'generate-password';

const Input = (props) => {
  const { disabled, input, label, placeholder, name, onChangeValue, type, isRenderLabel = true, meta: { touched, error }, isPassword = false, handlePassword } = props
  const [showPass, setShowPass] = useState(false);

  const handleGeneratePassword = () => {
    const password = generator.generate({
      length: 8,
      numbers: true
    })

    if(handlePassword){
      handlePassword(password);
    }
  }

  return (
  <div className={`form-group ${touched && error ? "has-error has-feedback" : ""}`}>
    {
      isRenderLabel && type !== "checkbox" &&
      <label htmlFor={name}>{label}</label>
    }
    {
      type === "checkbox" &&
      <label htmlFor={name} className="custom-checkbox">{label}
        <input className={`${type === "checkbox" ? "" : "form-control" }`} {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={type} onChange={(e) => {
          input.onChange(e);
          if (onChangeValue) {
            onChangeValue(e);
        }
        }} />
        <span className="checkmark checkmark-smaller"></span>
      </label>
    }
    {
      isPassword &&
        <InputGroup>
          <input className="form-control" {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={showPass ? 'text' : 'password'} onChange={(e) => {
            input.onChange(e);
            if (onChangeValue) {
              onChangeValue(e);
            }
            }} />
          <InputGroup.Text>
            <FontAwesomeIcon onClick={handleGeneratePassword} className="cursor-pointer" icon={solid('key')}/>
          </InputGroup.Text>
          <InputGroup.Text>
            <FontAwesomeIcon onClick={() => setShowPass(!showPass)} className="cursor-pointer" icon={showPass ? solid('eye') : solid('eye-slash')}/>
          </InputGroup.Text>
        </InputGroup>
    }
    { type !== "checkbox" && !isPassword &&
      <input className={`${type === "checkbox" ? "" : "form-control" }`} {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={type} onChange={(e) => {
        input.onChange(e);
        if (onChangeValue) {
          onChangeValue(e);
        }
      }} />
    }
    {
      touched && error &&
        <span style={{color: '#d15d5d'}}>
        <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
        {error}
      </span>
    }
  </div>
  )
}

export default Input;
