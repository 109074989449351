import { Modal, Button } from "react-bootstrap"

const AddPointsModal = ({ onHide, show, user = {}, addPoints }) => {
  const submit = (e) => {
    e.preventDefault();
    let form = new FormData(e.target);
    addPoints(form.get('points'), form.get('remarks'))
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ top: "15%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Points for {user.name}{user.coway_id ? ` (${user.coway_id})` : ''}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="d-grid gap-3">
          <div className="form-group">
            <label>Points</label>
            <input
              type="number"
              name="points"
              className="form-control"
              placeholder="Points"
              step="1"
              onFocus={(e) => e.target.select()}
            />
          </div>
          <div className="form-group">
            <label>Remarks</label>
            <textarea className="form-control" name="remarks" placeholder="Remarks"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddPointsModal
