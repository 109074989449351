import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import states from '../data/states.json'
import api from '../helpers/apiClient';

const EditAddressModal = ({ show, onClose, address = {} }) => {
  const submit = async (e) => {
    e.preventDefault();

    let formData = new FormData(e.target)

    return api('/user/profile/address', {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(() => {
      console.log('updated');
    });
  }

  return (
    <Modal show={show > -1} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4>Edit Address</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <Row>
            <Col md={6}>
              <label>First Name</label>
              <input className="form-control" name="first_name" defaultValue={address.first_name || ''}/>
            </Col>
            <Col md={6}>
              <label>Last Name</label>
              <input className="form-control" name="last_name" defaultValue={address.last_name || ''}/>
            </Col>
          </Row>
          <div>
            <label>Address</label>
            <input className="form-control" name="address1" defaultValue={address.address1 || ''}/>
          </div>
          <div>
            <label>Apartment, suite, etc.</label>
            <input className="form-control" name="address2" defaultValue={address.address2 || ''} />
          </div>
          <Row>
            <Col md={4}>
              <label>Postcode</label>
              <input className="form-control" name="zip" defaultValue={address.zip || ''}/>
            </Col>
            <Col md={4}>
              <label>City</label>
              <input className="form-control" name="city" defaultValue={address.city || ''}/>
            </Col>
            <Col md={4}>
              <label>State/territory</label>
              <Form.Select name="province" defaultValue={address.province_code || ''}>
                <option value="">Select a state</option>
                { states.MY.map(option => (
                  <option key={option.key} value={option.value}>{option.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <div>
            <label>Phone</label>
            <input className="form-control" name="phone" defaultValue={address.phone || ''} />
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditAddressModal;
