import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import api from '../../helpers/apiClient';
import { Button } from 'react-bootstrap';
import * as Promise from "bluebird";

const FileUploadField = (props) => {
  const { input, label, placeholder, meta: { touched, error }, handleOnChange, multiple = false, isAdmin = false, disabled } = props
  const [uploading, setUploading] = useState(false);

  const handleDropFile = async (files, name) => {

    if(files && files.length > 0){
      setUploading(true);

      return Promise.map(files, async function (file) {
        var formData = new FormData();
        formData.append('file', file);
        formData.append('no_shorten', true);

        return api(`/file/upload/${name}`, {
          method: 'POST',
          body: formData
        }, {'Accept': 'application/json'})
          .then(data => {
            const url = data.file_short_url

            if(multiple){
              let result = []
              if(input.value){
                result = input.value.split('\n')
              }
              result.push(url)
              result = result.join('\n').trim();

              handleOnChange(name, result)
            } else {
              handleOnChange(name, url)
            }
            setUploading(false);
          })
      }, {
        concurrency: 1
      })
    }
  }


  return (
    <div className={`form-group d-grid gap-1 ${touched && error ? "has-error has-feedback" : ""}`}>
      <label>{label}</label>
      {
        !multiple &&
          <input disabled={!isAdmin} className={`form-control ${input.value ? '': 'd-none'}`} {...input} type="text" placeholder={placeholder} onChange={(e) => input.onChange(e)}/>
      }
      {
        multiple &&
          <textarea disabled={!isAdmin} className={`form-control ${input.value ? '': 'd-none'}`} {...input} placeholder={placeholder} onChange={(e) => input.onChange(e)}/>
      }
      {
        uploading &&
          <p>Uploading...</p>
      }
      {
        !uploading && !disabled &&
          <Dropzone
            disabled={disabled}
            multiple={multiple}
            onDrop={files => handleDropFile(files, input.name)}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} className="cursor-pointer">
                  <input {...getInputProps()} />
                  <Button variant="secondary" size="sm">Drag 'n' drop or click to select {multiple ? 'multiple files' : 'file'}</Button>
                </div>
              </section>
            )}
          </Dropzone>
      }
      {
        touched && error &&
          <span style={{color: '#d15d5d'}}>
            <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
            {error}
          </span>
      }
    </div>
  )
}

export default FileUploadField;
