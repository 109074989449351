import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from "react";
import { login, logout } from '../redux/slices/auth';
import { resetError } from '../redux/slices/error';

import Input from '../components/Form/Input';

import logo from '../images/coway-logo.png';

const validate = values => {
  const errors = {}
  if (!values.id_or_email) {
    errors.id_or_email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors
}

const Login = ({ handleSubmit, submitting }) => {
  const { loading, loaded, user } = useSelector(state => state.auth);
  const { error, errorMsg } = useSelector(state => state.error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetError())
    }
  }, [])

  useEffect(() => {
    if(user){
      return navigate('/')
    }
  }, [user])

  const submit = values => {

    dispatch(login(values))
  }

  return (
    <div className="login-backdrop vh-100">
      <Row className="w-100 ms-0 h-100" id="pwd-container">
        <Col xs={12} className="text-center d-flex align-items-center">
          <form className="login-form desktop-login d-grid gap-3 mx-auto shadow-sm" onSubmit={handleSubmit(submit)}>
            <img src={logo} width="240" className="mx-auto mt-5 mb-1"/>
            <p className="text-center mb-0 login-title">Gifts & Rewards Center</p>
            {
              error &&
                <Alert variant="danger">
                  <div>{errorMsg}</div>
                </Alert>
            }
            <Field className="form-control" name="id_or_email" label="Coway ID/Email" placeholder="Email/Coway ID" type="text" isRenderLabel={false} component={Input} />
            <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" isRenderLabel={false} component={Input} />
            <div style={{textAlign:'right'}}>
              <a href="./forgot_password" target="_blank" style={{textDecoration: 'none', color: '#31a5e1', fontSize: '0.9em'}}>Forgot password?</a>
            </div>
            <Button
              type="submit"
              disabled={submitting}
              className="mt-4"
            >
              {submitting ? 'LOGGING IN...' : 'LOG IN'}
            </Button>
            <Link to="/signup" style={{textDecoration: 'none', color: '#31a5e1'}}>First Time Login</Link>
          </form>
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  form: 'login',
  validate,
  destroyOnUnmount: true,
})(Login)
