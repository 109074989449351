import React, { Component } from 'react';
import _ from 'lodash'
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';

const ManualLineItems = ({ items, style }) => {

  return (
    <table className="table" style={style}>
      <tbody>
        {
          items.map(item => {
            return (
              <tr key={item._id}>
                <td className="line-item-detail">
                  {
                    item.vendor ?
                    <div className="d-grid mb-2">
                      <label>Vendor Name</label>
                      <span>{item.vendor}</span>
                    </div>
                    :
                    null
                  }
                  {
                    item.delivery_date &&
                    <div className="d-grid mb-2">
                      <label>Delivery Date</label>
                      <span>{moment(item.delivery_date).format('DD/MM/YYYY')}</span>
                    </div>
                  }
                  {
                    item.order_file &&
                    <div className="d-grid mb-2">
                      <label>Order File</label>
                      <a href={item.order_file} target="_blank">{item.order_file}</a>
                    </div>
                  }
                  {
                    item.item_details &&
                    <div className="d-grid mb-2">
                      <label>Item Details</label>
                      <p className="order-detail-item-desc">{item.item_details}</p>
                    </div>
                  }
                  {
                    item.message_on_card &&
                    <div className="d-grid">
                      <div>
                      <label>Message on Card</label>
                      <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={item.message_on_card}>
                        COPY
                      </Button>
                      </div>
                      <p className="order-detail-item-desc">{item.message_on_card}</p>
                    </div>
                  }
                </td>
                <td className="text-end" style={{verticalAlign: "middle"}}>
                  { item.ready_to_ship &&
                  <div>
                    <Badge bg="success" className="text-uppercase mb-2">Accepted</Badge><br/>
                  </div>
                  }
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  );
}

export default ManualLineItems;
