import React from 'react';
import { Loader as RLoader } from 'react-loaders';

const Loader = ({ isVerticalCenter }) => {
  return (
    isVerticalCenter ?
    <div className="myloader v-center">
      <div className="text-center">
        <RLoader active type="line-scale" color="#31A5E1" />
      </div>
    </div>
    :
    <div className="text-center">
      <RLoader active type="line-scale" color="#31A5E1" />
    </div>
  );
}

export default Loader;
