const globby = require('globby');

module.exports = Object.assign({},
	require('./env/all'),
);

/**
 * Get files by glob patterns
 */
module.exports.getGlobbedFiles = function (globPatterns, removeRoot) {
  return globby.sync(globPatterns);
};

