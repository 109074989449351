import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  item: null,
}

export const fetchManualOrderDetail = createAsyncThunk('manual_order/fetch', async (orderId, thunkAPI) => {
  try {
    return await api(`/manual_orders/${orderId}`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const manualOrderDetailSlice = createSlice({
  name: 'manual_order',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManualOrderDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchManualOrderDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.item = action.payload.order
        state.lastUpdated = action.payload.receivedAt
      })
      .addCase(fetchManualOrderDetail.rejected, (state, action) => {
        state.loading = false;
        state.item = null;
      })
  }
})

export default manualOrderDetailSlice.reducer
