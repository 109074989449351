import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  items: [],
}

export const listQuickHelps = createAsyncThunk('quick_helps/list', async (_, thunkAPI) => {
  try {
    return await api(`/quick_help/list`, {
      method: 'GET',
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const quickHelpSlice = createSlice({
  name: 'quick_helps',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(listQuickHelps.pending, (state) => {
        state.loading = true;
      })
      .addCase(listQuickHelps.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = [...action.payload]
      })
      .addCase(listQuickHelps.rejected, (state) => {
        state.loading = false;
        state.items = [];
      })
  }
})

export default quickHelpSlice.reducer
