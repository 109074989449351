import { useSelector } from 'react-redux';
import { Button, Card, Badge } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import TabHeader from '../components/TabHeader';
import CreateDealModal from '../components/CreateDealModal';
import api from '../helpers/apiClient';

const Rewards = () => {
  const [showCreateDealModal, setShowCreateDealModal] = useState(false);
  const [deals, setDeals] = useState([]);
  const { user } = useSelector(state => state.auth);
  const isAdmin = user && user.role === 'admin';

  useEffect(() => {
    if (deals.length === 0) {
      getDeals();
    }
  }, [])

  const getDeals = async () => {
    return api('/deals').then(res => {
      setDeals(res);
    })
  }

  const renderMarketingKit = (col) => {

    return (
      <a href={col} target="_blank">
        <Button variant="secondary" className="btn-br-6 d-print-none">
          Download
          <FontAwesomeIcon className='mx-1' icon={solid('download')}/>
        </Button>
      </a>
    )
  }

  const renderValidity = (_, row) => {
    const { start_at, end_at } = row

    return `${moment(start_at).format('DD/MM/YYYY')} - ${moment(end_at).format('DD/MM/YYYY')}`
  }

  const renderStatus = (_, row) => {
    const { start_at, end_at } = row
    const now = moment();

    if (now.isBefore(moment(start_at))) {
      return (
        <Badge bg="warning text-uppercase">Upcoming</Badge>
      )
    } else if (now.isAfter(moment(end_at))) {
      return (
        <Badge bg="default text-uppercase">Expired</Badge>
      )
    } else {
      return (
        <Badge bg="success text-uppercase">Active</Badge>
      )
    }
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  const dealsCol = [
    { dataField: '_id', text: '', hidden: true },
    { dataField: 'created_at', sort: true, sortFunc: dateSort,  text: '', hidden: true },
    { dataField: 'title', text: 'Title', style: { whiteSpace: 'pre-line' }, headerStyle: { width: '200px' } },
    { dataField: 'reward_info', text: 'Info', style: { whiteSpace: 'pre-line' }, headerStyle: { width: '355px', } },
    { dataField: 'start_at', text: 'Validity', formatter: renderValidity, headerStyle: { width: '220px' } },
    { dataField: 'status', text: 'Status', formatter: renderStatus, headerStyle: { width: '120px' } },
    { dataField: 'marketing_kit', text: 'Marketing Comms', formatter: renderMarketingKit, headerStyle: { width: '120px', } },
  ]

  return (
    <>
      <TabHeader
        title="Rewards Program"
        tab_id="reward_program"
        children={
          <>
            {
              isAdmin &&
                <Button onClick={() => setShowCreateDealModal(true)} variant="primary" className="btn-br-6 d-print-none">Create Deal</Button>
            }
          </>
        }
      />
      <Card className="curve-border shadow-sm mb-4">
        <Card.Body className="p-4">
          <h4>Deals</h4>
          <ToolkitProvider
            keyField='_id'
            data={deals}
            columns={dealsCol}
          >
            {
              props => (
                <>
                  <BootstrapTable
                    { ...props.baseProps }
                    bordered={ false }
                    pagination={deals.length > 10 ? paginationFactory({
                      options: {
                        showTotal: true,
                          sizePerPage: 10,
                      },
                        sizePerPageList: [{
                          text: '10', value: 10
                        }, {
                          text: '25', value: 25
                        }, {
                          text: '50', value: 50
                        }, {
                          text: 'All', value: deals.length
                        }],
                    }) : undefined}
                    hover
                    noDataIndication={() => 'No active deals, fill the form below to start!'}
                    rowClasses="align-middle"
                    defaultSorted={[{
                      dataField: 'created_at',
                        order: 'desc',
                    }]}
                  />
                </>
              )
            }
          </ToolkitProvider>
        </Card.Body>
      </Card>
      <p className="font-large">For company/organisations that are interested to partner with Giftr to offer exclusive deals to employees or network of agents for their purchase at Giftr as part of your rewards or benefits program, do fill up the request form below.</p>

      <p className="font-large">Our team will get in touch to create a customised deal or promotion (fully subsidised by Giftr or co-fund) along with marketing materials for your internal communications.</p>

      <p className="font-large">Example:</p>
      <ul className="font-large">
        <li>RM10 OFF with min. spend of RM100 for purchase at Giftr using  account registered with company email domain: @company_name.com</li>
      </ul>
      <div className="d-flex justify-content-center">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdW3jzm5OXcUzNRYUVWvScpZEAubAU3L6QWItum__7l9ZolaQ/viewform?embedded=true" width="900" height="1200" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>
      {
        showCreateDealModal &&
          <CreateDealModal onClose={() => setShowCreateDealModal(false)} />
      }
    </>
  )
}

export default Rewards;
