import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';
import { useDispatch } from 'react-redux';

import Input from '../components/Form/Input'
import UserSelection from './UserSelection';

import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';

const validate = values => {
  const errors = {}
  if(!values.code) {
    errors.code = 'Required';
  }
  if(!values.giftcard_id) {
    errors.giftcard_id = 'Required';
  }
  if(!values.initial_value) {
    errors.initial_value = 'Required';
  }
  if(!values.email) {
    errors.email = 'Required';
  }
  return errors
}

let AddGiftCardModal = ({ submitting, handleSubmit, onClose, onSubmit, showPurchaseId = true, postpaid = false }) => {
  const [formValues, setFormValues] = useState({
    expires_on: null,
  })
  const [focused, setFocused] = useState(null)
  const dispatch = useDispatch();

  const min = "2022-01-01", max = "2025-01-01";

  const returnYears = () => {
    let years = [];
    for (let i = parseInt(moment(min).format("YYYY")); i <= parseInt(moment(max).format("YYYY")); i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select
            className='form-control-sm'
            style={{appearance: 'none'}}
            value={
              moment(month).isBefore(moment(min))
                ? moment(min).month()
                : month.month()
            }
            onChange={e => {
              if (month.isBefore(moment(min))) {
                onMonthSelect(moment(min), moment(min).month());
              } else {
                onMonthSelect(month, e.target.value);
              }
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div>
          <select
            className='form-control-sm'
            style={{appearance: 'none'}}
            value={month.year()}
            onChange={e => {
              onYearSelect(month, e.target.value);
            }}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
  }

  const submit = async values => {
    let result = await api(postpaid ? '/giftcard/postpaid/create' : '/giftcard/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        expires_on: moment(formValues.expires_on).utcOffset(8).startOf('day').toDate(),
      })
    }).catch(err => {
      dispatch(addError(err.message));
    })

    if(result){
      if(result.success){
        onSubmit();
      } else if(result.message) {
        dispatch(addError(result.message));
      }
    }
  }

  return (
    <Modal style={{top:'15%', border: 'none'}} show={true} onHide={() => onClose()} size="md">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <Modal.Title>Add Gift Card</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <div className="d-grid gap-1">
            <Field className="form-control" required name="email" label="Client" type="text" component={UserSelection} />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="code" label="Gift card code" placeholder="Gift card code" type="text" component={Input} />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="giftcard_id" label="Gift card ID" placeholder="Gift card ID" type="text" component={Input} />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="initial_value" label="Initial value" placeholder="Initial value" type="number" component={Input} />
          </div>

          <div className="d-grid gap-1">
            <label htmlFor="expires_on">Expires on</label>
            <SingleDatePicker
              date={formValues.expires_on}
              onDateChange={date => {
                setFormValues({
                  ...formValues,
                  expires_on: date
                })
              }}
              displayFormat="DD-MM-YYYY"
              numberOfMonths={1}
              showClearDate={true}
              small
              block
              showDefaultInputIcon={true}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              renderMonthElement={renderMonthElement}
              hideKeyboardShortcutsPanel
              keepOpenOnDateSelect={false}
            />
          </div>

          {
            showPurchaseId &&
              <div className="d-grid gap-1">
                <Field className="form-control" required name="purchase_id" label="Purchase ID" placeholder="Purchase ID" type="text" component={Input} />
              </div>
          }

        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button
            variant="primary"
            type="submit"
            disabled={ submitting }
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

AddGiftCardModal = reduxForm({
  form: 'AddGiftCard',
  validate,
  destroyOnUnmount: true,
})(AddGiftCardModal);

export default AddGiftCardModal
