import cartTemplates from '../templates/cart';
import _ from 'lodash';

export const cartConfig = (ui) => {
  return {
    templates: {
      footer: cartTemplates.footer,
    },
    DOMEvents: {
      'click .browse-more-btn': function() {
        let cart = ui.components.cart[0]
        let lineItems = cart.lineItemCache.map(lineItem => {
          return lineItem.variant.id.replace('gid://shopify/ProductVariant/', '') + '_' + lineItem.quantity
        })

        const url = `https://giftr.my/collections/all?add_to_cart=${lineItems.join(',')}`
        window.open(url, '_blank')
      }
    },
    classes: {
      browseMoreBtn: 'browse-more-btn'
    },
    styles: {
      browseMoreBtn: {
        'clear': 'both',
        'margin-top': '15px',
        'width': '100%',
        'padding': '10px 5px',
        'font-size': '16px',
        'letter-spacing': '.3px',
        'display': 'block',
        'border-radius': '3px',
        'cursor': 'pointer',
        'transition': 'background 200ms ease',
        'max-width': '100%',
        'text-overflow': 'ellipsis',
        'overflow': 'hidden',
        'line-height': '1.2',
        'border': '0',
        '-webkit-appearance': 'none',
        'text-decoration': 'none',
        'color': 'white',
        'background-color': '#81d8d0',
        'text-align': 'center',
        ':hover': {
          'background-color': '#5cd1c6',
        }
      },
      button: {
        'background-color': '#f37022',
        ':hover': {
          'background-color': '#DF5B0C',
        }
      },
      wrapper: {
        'max-width': '400px',
      }
    },
    text: {
      notice2: 'Please use the same email address during checkout.',
    },
    // events: {
    //   afterInit: function(cart) {
    //     // NOTE: manually add to cart
    //     ui.client.checkout.addLineItems(cart.model.id, [{
    //       variantId: "gid://shopify/ProductVariant/43383615422625",
    //       quantity: 1,
    //       customAttributes: {
    //         key: 'redeemed',
    //         value: 'true'
    //       }
    //     }]).then((checkout) => {
    //       cart.model = checkout;
    //       cart.updateCache(cart.model.lineItems);
    //       cart.view.render();
    //       cart.toggles.forEach((toggle) => toggle.view.render());
    //       cart.open()
    //       // if (!openCart) {
    //       //   this.setFocus();
    //       // }
    //     })

    //     // NOTE: manually apply discount
    //     ui.client.checkout.addDiscount(cart.model.id, "NFZHCVZU").then((checkout) => {
    //       cart.model = checkout;
    //       cart.updateCache(cart.model.lineItems);
    //       cart.view.render();
    //       cart.toggles.forEach((toggle) => toggle.view.render());
    //       cart.open()
    //     })

    //   }
    // }
  }
}

export const isCartEmpty = (carts) => {
  if (carts.length === 0) {
    return true
  }

  return _.every(carts, (cart) => {
    return !cart.id || cart.lineItems.length === 0
  })
}
