import { Field, reduxForm } from 'redux-form';
import { Row, Card, Col, Button, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { Transition } from 'react-transition-group';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Input from '../components/Form/Input';
import GFBAlert from '../components/GFBAlert';
import ChangePasswordModal from '../components/ChangePasswordModal';
import TabHeader from '../components/TabHeader';
import Loader from '../components/Loader';

import { getProfile } from '../redux/slices/user';
import { addError } from '../redux/slices/error';
import api from '../helpers/apiClient';
import { fadeTransition } from '../styles/transitions';
import EditAddressModal from '../components/EditAddressModal';

let UserInfoForm = reduxForm({
  form: 'user_info',
  destroyOnUnmount: true,
})(({ submitUserInfo, handleSubmit, submitting, setShowChangePassword }) => {

  const customValidate = useCallback(
    value => ((typeof value === 'string' && value !== "") ? undefined : 'Required'),
    []
  );

  return (
    <form>
      <Row className="mx-0 justify-content-center">
        <Col lg={6}>
          <Card className="tealCard shadow-sm">
            <Card.Header className="tealCardHeader">
              <Row className="align-items-center justify-content-between">
                <Col xs="auto">User Info</Col>
                <Col xs="auto">
                  <Button variant="danger" className="btn-sm" onClick={() => setShowChangePassword(true)}>
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="m-2 d-grid gap-3">
              <div className="d-grid gap-3">
                <Field disabled className="form-control" name="coway_id" label="Coway ID" type="text" component={Input} />
                <Field className="form-control" name="name" label="Name" placeholder="Name" type="text" component={Input} validate={customValidate} />
                <Field disabled className="form-control" name="email" label="Email" placeholder="Email" type="text" component={Input} />
                <Field className="form-control" name="phone_number" label="Phone Number" placeholder="Phone Number" type="text" component={Input} />
              </div>
              <div className='d-grid justify-content-center'>
              <Button
                onClick={handleSubmit(submitUserInfo)}
                className="px-4"
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                {submitting ? 'SAVING' : 'SAVE'}
              </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  )
})

UserInfoForm = connect(state => {
  return {
    initialValues: state.user.item,
  };
})(UserInfoForm);

let Profile = ({ showHeader = true }) => {
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(-1);
  const dispatch = useDispatch()
  const { item: profile, loading, loaded } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch])

  const submitUserInfo = async (values) => {
    const { name, phone_number } = values;
    const url = '/user/profile/update';

    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name, phone_number
      })
    }).then(() => {
      setSuccess(true)
      setAlert('Updated Successfully')
    }).catch(err => {
      dispatch(addError(err))
    });
  }

  const handleOnChangePassword = async (newPassword) =>  {

    let body = {
      password: newPassword
    }

    return api(`/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then((result) => {
      if(result.success){
        setSuccess(true)
        setAlert('Password Updated')
      } else {
        dispatch(addError("Fail to update password, please contact Giftr support"))
      }
      setShowChangePassword(false)
    })
  }

  return (
    <>
      <Transition in={success} timeout={150}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={alert} handleClose={() => {
              if(success){
                setSuccess(false)
              }
            }} />
        )}
      </Transition>
      {
        showHeader &&
          <TabHeader
            title="Profile"
            tab_id="profile"
          />
      }
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded && profile &&
          <div className="d-grid gap-3">
            <UserInfoForm submitUserInfo={submitUserInfo} setShowChangePassword={setShowChangePassword} />
            <Row className="mx-0 justify-content-center">
              <Col lg={6}>
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">
                    Addresses
                  </Card.Header>
                  <Card.Body>
                    <ListGroup>
                      { profile?.addresses?.map((address, index) => (
                        <ListGroup.Item key={index} className="d-grid gap-2 py-3 position-relative">
                          <span>{address.first_name} {address.last_name}</span>
                          <span>{address.address1} {address.address2} {address.zip} {address.city} {address.province} {address.country}</span>
                          <span>{address.phone}</span>
                          <Button className="position-absolute end-0 top-50 translate-middle-y mx-4" variant="secondary" onClick={() => setShowEditAddress(index)}>
                            <FontAwesomeIcon icon={solid('pen')}/>
                          </Button>
                        </ListGroup.Item>
                      )) }
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
      }
      {
        showChangePassword &&
          <ChangePasswordModal onClose={() => setShowChangePassword(false)} onClickSave={handleOnChangePassword}/>
      }
      <EditAddressModal show={showEditAddress} onClose={() => setShowEditAddress(-1)} address={profile?.addresses && profile.addresses[showEditAddress]}/>
    </>
  )
}

export default Profile;
