import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Dropzone from 'react-dropzone';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addError } from '../redux/slices/error';

import api from '../helpers/apiClient';

const UploadUsersModal = ({ show, onClose, onSubmitted }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false);

  const downloadSampleFile = () => {
    return fetch('/api/user/bulk/csv', {
      method: 'GET',
      headers: { 'Accept': 'text/csv' }
    }).then(res => res.blob()).then(blob => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `sample-upload-users.csv`,
      );

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    })
  }

  const handleDropFile = (file) => {
    setFile(file[0])
  }

  const handleUpload = () => {
    setUploading(true)

    var formData = new FormData();
    formData.append('file', file);
    return api(`/users/upload`, {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(async () => {
      onSubmitted()
      setFile(null)
    }).catch(err => {
      dispatch(addError(err))
    }).finally(() => {
      setUploading(false)
    })
  }

  const handleClose = () => {
    setFile(null)
    onClose()
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Upload Users & Rewards</h4>
      </Modal.Header>
      <Modal.Body>
        <p>Use this feature to:</p>
        <ol>
          <li>Create new users</li>
          <li>Update/add points rewarded for existing users</li>
        </ol>
        <p>Upload a CSV file (.csv) containing users with the following headers: </p>
        <p>Coway ID (Required), Role (Required, values: "agent", "admin", "staff"), Name, Email, Phone Number, Points Rewarded, Remarks For Points</p>
        <p>For example:</p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Coway ID</th>
              <th>Role</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Points Rewarded</th>
              <th>Remarks For Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12345</td>
              <td>agent</td>
              <td>John Smith</td>
              <td style={{wordBreak: 'break-all'}}>johnsmith@example.com</td>
              <td>0123456789</td>
              <td>100</td>
              <td>Awarded for Monthly Sales</td>
            </tr>
          </tbody>
        </table>
        <div className="mb-2">
          <span>Download sample file here:</span>
          <Button variant="secondary" className="mx-2" onClick={downloadSampleFile}><FontAwesomeIcon icon={solid('download')}/></Button>
        </div>
        <div>
          <span>Upload completed file here:</span>
          <Dropzone
            multiple={false}
            onDrop={handleDropFile}
            className="d-inline-block"
          >
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="cursor-pointer d-inline-block">
                <input {...getInputProps()} />
                <Button variant="secondary" className="mx-2"><FontAwesomeIcon icon={solid('upload')}/></Button>
                {file && <span>{file.name}</span>}
              </div>
            )}
          </Dropzone>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" disabled={!file || uploading} onClick={handleUpload}>Upload</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadUsersModal;
