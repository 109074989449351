import React from 'react';
import { Loader as RLoader } from 'react-loaders';

const OverlayLoader = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100vh',
      width: '100%',
      zIndex: '10',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    }}>
      <RLoader active type="line-scale" color="#2BCCB1" />
    </div>
  );
}

export default OverlayLoader;
