import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../helpers/apiClient';

const initialState = {
  loading: false,
  loaded: false,
  user: null
}

export const login = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
  try {
    return await api('/auth/login', {
      method: 'POST',
      body: JSON.stringify(userData)
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.loaded = false;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.user = action.payload.user;
        state.lastUpdated = action.payload.receivedAt;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.user = null;
      })
  }
})

export const { logout } = authSlice.actions

export default authSlice.reducer
