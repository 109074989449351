import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import error from './error';
import orders from './orders';
import orderDetail from './orderDetail';
import users from './users';
import user from './user';
import manualOrderDetail from './manualOrderDetail';
import dashboard from './dashboard';
import quickHelp from './quickHelp';
import logs from './logs';

const appReducer = combineReducers({
  form: formReducer,
  auth,
  error,
  orders,
  orderDetail,
  users,
  user,
  manualOrderDetail,
  dashboard,
  quickHelp,
  logs,
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;
