import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Badge, Container, Col } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import _ from 'lodash';

import User from './User';
import GFBAlert from '../components/GFBAlert';
import Loader from '../components/Loader';
import ConfirmModal from '../components/ConfirmModal';
import TabHeader from '../components/TabHeader';
import AddPointsModal from '../components/AddPointsModal';
import api from '../helpers/apiClient';

import { listUsers } from '../redux/slices/users';
import { clearUser } from '../redux/slices/user';
import { fadeTransition } from '../styles/transitions';
import UploadUsersModal from '../components/UploadUsersModal';

const { SearchBar } = Search;

const Users = () => {
  const { items, loading, loaded } = useSelector(state => state.users);
  const { role } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const isAdmin = role === 'admin';

  const [selectedUser, setSelectedUser] = useState({});
  const [cowayId, setCowayId] = useState('');
  const [showConfirmDeactivate, setShowConfirmDeactivate] = useState(false);
  const [showUploadUsersModal, setShowUploadUsersModal] = useState(false);
  const [showAddPointsModal, setShowAddPointsModal] = useState(false);
  const [createUser, setCreateUser] = useState({
    show: false,
    success: false,
    successId: null
  });

  const [editUser, setEditUser] = useState({
    show: false,
    success: false,
    userId: null,
    message: ''
  })

  useEffect(() => {
    dispatch(listUsers());
  }, [])

  useEffect(() => {
    if(createUser.success || editUser.success){
      dispatch(listUsers());
    }
  }, [createUser.success, editUser.success])

  const renderDetailLink = (cell, row) => {
    return (
      <a
        onClick={() => {
          setSelectedUser(row)
          setEditUser({ show: true, userId: row._id })
        }}
        href="#"
      >
        View
      </a>
    )
  }

  const renderActive = (cell, row) => {
    return <Badge bg={cell ? "success" : "warning"} className="text-uppercase">{cell ? 'yes' : 'no'}</Badge>
  }

  const renderActivateButton = (cell, row) => {
    if (row.active) {
      return (
        <Button variant="danger" onClick={() => {
          setCowayId(row.coway_id)
          setSelectedUser(row)
          setShowConfirmDeactivate(true)
        }}>Deactivate</Button>
      )
    }
    return (
      <Button variant="primary" onClick={() => {
        activateUser(row.id)
      }}>Activate</Button>
    )
  }

  const renderPointsButton = (cell, row) => {
    return (
      <Button variant="secondary" onClick={() => {
        setSelectedUser(row)
        setShowAddPointsModal(true)
      }}>Add Points</Button>
    )
  }

  const deleteUser = () => {
    setEditUser({ ...editUser, show: false })
    return api(`/users/${editUser.userId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      dispatch(clearUser())
      setEditUser({ ...editUser, show: false, userId: null })
      dispatch(listUsers());
    }).catch(err => {
      console.log(err);
    });
  }

  const activateUser = (id) => {
    return api(`/users/${id}/activate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      dispatch(listUsers());
    }).catch(err => {
      console.log(err);
    });
  }

  const deactivateUser = () => {
    setShowConfirmDeactivate(false);
    return api(`/users/${selectedUser.id}/deactivate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      dispatch(listUsers());
    }).catch(err => {
      console.log(err);
    });
  }

  const addPoints = (amount, remarks) => {
    setShowAddPointsModal(false);
    return api(`/points/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: selectedUser._id, user_coway_id: selectedUser.coway_id, amount, remarks })
    }).then(() => {
      dispatch(listUsers());
    }).catch(err => {
      console.log(err);
    });
  }

  const renderPoints = (col) => {
    let points = _.reduce(col, (sum, item) => {
      return sum + item.balance
    }, 0)

    return points
  }

  const handleBulkUploaded = () => {
    setShowUploadUsersModal(false);
    dispatch(listUsers());
  }

  const columns = [
    { dataField: '_id', text: 'View', formatter: renderDetailLink, headerStyle: { width: '110px', } },
    { dataField: 'coway_id', text: 'Coway ID', sort: true, headerStyle: { width: '110px', } },
    { dataField: 'name', text: 'Name', sort: true, headerStyle: { width: '110px', } },
    { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: '110px', } },
    { dataField: 'role', text: 'Role', sort: true, hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'active', formatter: renderActive, sort: true, text: 'Active', headerStyle: { width: '85px', }, },
    { dataField: 'activate', text: '', formatter: renderActivateButton, hidden: !isAdmin, headerStyle: { width: '110px' } },
    { dataField: 'points', sort: true, text: 'Points', formatter: renderPoints, headerStyle: { width: '85px', }, },
    { dataField: 'add_points', text: '', formatter: renderPointsButton, hidden: !isAdmin, headerStyle: { width: '110px' } },
  ];

  const options = {
    showTotal: true,
    sizePerPage: 10,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: 'All', value: items && items.length }
    ],
  };

  return (
    <>
      <Transition in={editUser.success} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                {editUser.message}
              </>
            } handleClose={() => {
              if(createUser.success){
                setEditUser({ ...editUser, success: false, email: '' })
              }
            }} />
        )}
      </Transition>
      <Transition in={createUser.success} timeout={300}>
        {state => (
          <GFBAlert
            style={{
              opacity: '0',
              ...fadeTransition[state]
            }}
            variant="success" alert={
              <>
                Created new user.{" "}
                {
                  role === 'client' &&
                    <span>Verification link has been sent to their email.</span>
                }
              </>
            } handleClose={() => {
              if(createUser.success){
                setCreateUser({ ...createUser, success: false })
              }
            }} />
        )}
      </Transition>
      <TabHeader
        title="Users & Rewards"
        tab_id="users_rewards"
        children={
          <>
            {
              role === 'admin' &&
                <>
                  <Button
                    variant="primary"
                    className="btn-br-6"
                    onClick={() => setShowUploadUsersModal(true)}
                  >
                    Upload Users & Rewards
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-br-6"
                    onClick={() => setCreateUser({ ...createUser, show: true })}
                  >
                    Create User
                  </Button>
                </>
            }
          </>
        }
      />
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded &&
          <ToolkitProvider
            keyField='_id'
            data={items}
            columns={columns}
            search
          >
            {
              props => (
                <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                    <Col></Col>
                    <Col xs={4} className='search-bar-full-width'>
                      <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} placeholder="Search"/>
                    </Col>
                  </Container>
                  <BootstrapTable
                    { ...props.baseProps }
                    bordered={ false }
                    pagination={paginationFactory(options)}
                    hover
                  />
                </>
              )
            }
          </ToolkitProvider>
      }
      <User
        isCreate={true}
        onClose={() => setCreateUser({ ...createUser, show: false })}
        onSubmit={id => setCreateUser({
          show: false,
          success: true,
          successId: id
        })}
        show={createUser.show}
      />
      <User
        isAdmin={isAdmin}
        onClose={() => {
          dispatch(clearUser())
          setSelectedUser({})
          setEditUser({ ...editUser, show: false, userId: null })
        }}
        onSubmit={message => setEditUser({
          show: false,
          success: true,
          message,
        })}
        userId={editUser.userId}
        show={editUser.show}
        deleteUser={deleteUser}
        user={selectedUser}
      />
      <UploadUsersModal show={showUploadUsersModal} onClose={() => setShowUploadUsersModal(false)} onSubmitted={handleBulkUploaded}/>
      <ConfirmModal title="Deactivate User" onClose={() => setShowConfirmDeactivate(false)} onClickYes={deactivateUser} show={showConfirmDeactivate}>
        <p>Confirm deactivate this user (ID: {cowayId})?</p>
      </ConfirmModal>
      <AddPointsModal show={showAddPointsModal} onHide={() => setShowAddPointsModal(false)} user={_.find(items, user => user.id === selectedUser.id)} addPoints={addPoints}/>
    </>
  )
}

export default Users;
