import { logout } from '../redux/slices/auth';
import store, { history } from '../redux/store';

export default function apiClient(uri, options = {}, headersOveride) {
  options.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...options.headers
  };

  if(headersOveride){
    options.headers = headersOveride
  }

  const { user }  = store.getState().auth;
  if (user && user.token){
    options.headers['Authorization'] = `Bearer ${user.token}`;
  }

  const url = `/api${uri}`;
  return fetch(url, options).then(res => {
    return res.json().then(json => {
      if(res.status >= 200 && res.status < 300) {
        return json;
      }
      else if (res.status === 401) {
        store.dispatch(logout());
        return Promise.reject(json);
      }
      else {
        // console.log('api error', json)
        return Promise.reject(json);
      }
    });
  });
}
