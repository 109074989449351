import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { reduxForm, Field, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { useDispatch, connect } from 'react-redux';
import _ from 'lodash'

import Input from './Form/Input'
import FileUpload from './Form/FileUpload';
import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const validate = values => {
  const errors = {}
  if(!values.code) {
    errors.code = 'Required';
  }
  if(!values.giftcard_id) {
    errors.giftcard_id = 'Required';
  }
  if(!values.initial_value) {
    errors.initial_value = 'Required';
  }
  if(!values.email) {
    errors.email = 'Required';
  }
  return errors
}

let MarkAsPaidModal = ({ submitting, handleSubmit, onClose, onSubmit, selectedPurchase, change, isAdmin }) => {
  const [formValues, setFormValues] = useState({
    payment_date: null,
  })
  const [focused, setFocused] = useState(null)
  const dispatch = useDispatch();

  const min = "2022-01-01", max = "2025-01-01";

  const returnYears = () => {
    let years = [];
    for (let i = parseInt(moment(min).format("YYYY")); i <= parseInt(moment(max).format("YYYY")); i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select
            className='form-control-sm'
            style={{appearance: 'none'}}
            value={
              moment(month).isBefore(moment(min))
                ? moment(min).month()
                : month.month()
            }
            onChange={e => {
              if (month.isBefore(moment(min))) {
                onMonthSelect(moment(min), moment(min).month());
              } else {
                onMonthSelect(month, e.target.value);
              }
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div>
          <select
            className='form-control-sm'
            style={{appearance: 'none'}}
            value={month.year()}
            onChange={e => {
              onYearSelect(month, e.target.value);
            }}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
  }

  const submit = async values => {
    let result = await api('/purchase/mark/paid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        payment_date: moment(formValues.payment_date).utcOffset(8).startOf('day').toDate(),
        purchase_id: selectedPurchase._id,
      })
    }).catch(err => {
      dispatch(addError(err.message));
    })

    if(result){
      if(result.success){
        onSubmit();
      } else if(result.message) {
        dispatch(addError(result.message));
      }
    }
  }

  const handleFileUpload = (name, url) => {
    change(name, url);
  }

  return (
    <Modal show={true} onHide={() => onClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Mark as Paid</Modal.Title>
      </Modal.Header>
      <p className='p-4 m-0'>
        Client: {selectedPurchase.email}<br/>
        Amount Due: {currency}{(selectedPurchase.amount/100).toFixed(2)}<br/>
        Gift Card ID: {selectedPurchase.giftcard_id}<br/>
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{ borderTop: '1px solid #dee2e6' }}>
          <div className="d-grid gap-1">
            <label htmlFor="payment_date">Payment Date</label>
            <SingleDatePicker
              date={formValues.payment_date}
              onDateChange={date => {
                setFormValues({
                  ...formValues,
                  payment_date: date
                })
              }}
              displayFormat="DD-MM-YYYY"
              numberOfMonths={1}
              showClearDate={true}
              small
              block
              showDefaultInputIcon={true}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              renderMonthElement={renderMonthElement}
              hideKeyboardShortcutsPanel
              keepOpenOnDateSelect={false}
            />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="amount" label="Amount" placeholder="Amount" type="number" component={Input} />
          </div>

          <div className="d-grid gap-1">
            <Field name="invoice" label="Invoice" component={FileUpload} placeholder="Invoice" handleOnChange={handleFileUpload} isAdmin={isAdmin} />
          </div>

          <div className="d-grid gap-1">
            <Field name="sales_receipt" label="Sales Receipt" component={FileUpload} placeholder="Sales Receipt" handleOnChange={handleFileUpload} isAdmin={isAdmin} multiple={true}/>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting}
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

MarkAsPaidModal = reduxForm({
  form: 'MarkAsPaid',
  validate,
  destroyOnUnmount: true,
})(MarkAsPaidModal)

export default connect(() => {
  return {};
}, (dispatch) => {
  return bindActionCreators({ change }, dispatch);
})(MarkAsPaidModal);
