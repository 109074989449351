import { Modal, Button, ListGroup, Badge, Tabs, Tab } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash';
import { useState } from "react";

const PointsDetailModal = ({ show, onHide, points, history = [] }) => {
  const [historyCount, setHistoryCount] = useState(10);
  const [pointCount, setPointCount] = useState(10);

  if (!points || points.length === 0) {
    return null;
  }

  const renderStatus = (point) => {
    if (moment(point.expires_at).isBefore(moment())) {
      return <Badge bg="danger">Expired</Badge>;
    }
    return <Badge bg="success">Active</Badge>;
  }

  const renderHistoryBadge = (history) => {
    if (history.type === 'points_rewarded') {
      return <Badge bg="success">Points Rewarded</Badge>;
    }
    if (history.type === 'points_redeemed') {
      return <Badge bg="warning">Points Redeemed</Badge>;
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>
          <span>Points</span>
        </Modal.Title>
        <span>Balance: {_.reduce(points, (sum, point) => { return sum + point.balance }, 0)}</span>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="history" className="w-100">
          <Tab eventKey="history" title="History" tabClassName="points-tab">
            <ListGroup className="my-2">
              { _.map(_.slice(_.sortBy(history, (history) => {
                return -moment(history.created).valueOf()
              }), 0, historyCount), (history, index) => (
                <ListGroup.Item key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-grid gap-1">
                      <span>{history.type === 'points_rewarded' ? '+' : '-'}{history.points}</span>
                      <span className="small text-secondary">{moment(history.created).format('DD/MM/YYYY')}</span>
                    </div>
                    {renderHistoryBadge(history)}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            { history.length > historyCount &&
            <Button variant="primary" size="sm" onClick={() => setHistoryCount(historyCount + 2)}>Show More</Button>
            }
          </Tab>
          <Tab eventKey="points" title="Expiration" tabClassName="points-tab">
            <ListGroup className="my-2">
              { _.map(_.slice(_.sortBy(points, (point) => {
                return -moment(point.created).valueOf()
              }), 0, pointCount), (point, index) => (
                <ListGroup.Item key={index}>
                  <div className="d-flex justify-content-between">
                    <div className="d-grid gap-1">
                      <span>{point.value}</span>
                      <span className="small text-secondary">Balance: {point.balance} | Expires: {moment(point.expires_at).format('DD/MM/YYYY')}</span>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            { points.length > pointCount &&
            <Button variant="primary" size="sm" onClick={() => setPointCount(pointCount + 2)}>Show More</Button>
            }
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PointsDetailModal;
