import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import HttpsRedirect from 'react-https-redirect';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'loaders.css/loaders.min.css';
import 'react-dates/initialize';

import App from './containers/App';
import Login from './containers/Login';
import FirstTimeLogin from './containers/FirstTimeLogin';
import Verify from './containers/Verify';
import Signup from './containers/Signup';
import ForgotPassword from './containers/ForgotPassword';

import './styles/app.css'

let persistor = persistStore(store);

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HttpsRedirect>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<FirstTimeLogin />} />
              <Route path="/verify/:verified" element={<Verify />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
              <Route path="*" element={<App />} />
            </Routes>
          </Router>
        </HttpsRedirect>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(
  <Root/>,
  document.getElementById('react-root')
)
