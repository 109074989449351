import { useDispatch, connect } from "react-redux";
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import { Modal, Row, Card, Col, Button, ListGroup } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import Input from '../components/Form/Input';
import TextArea from '../components/Form/TextArea';
import Select from '../components/Form/Select';
import ConfirmModal from '../components/ConfirmModal';

import userRolesOptions from '../data/userRoles.json';
import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';
import { setUser, clearUser } from '../redux/slices/user';

const validate = values => {
  const errors = {}
  if(values.role !== 'admin' && !values.coway_id) {
    errors.coway_id = 'Required';
  }
  if(!values.name) {
    errors.name = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  if(!values.role){
    errors.role = 'Required';
  }
  return errors
}

let User = ({ isCreate, onClose, onSubmit, handleSubmit, submitting, show, deleteUser, user }) => {

  const dispatch = useDispatch();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    if (user && user._id) {
      dispatch(setUser(user))
    }
  }, [user])

  useEffect(() => {
    if (isCreate) {
      dispatch(clearUser())
    }
  }, [isCreate])

  const submit = async (values) => {
    const { coway_id, email, phone_number, password, role, name, points, remarks } = values;
    const url = '/users';

    if(isCreate){
      return api(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          coway_id, email, phone_number, role, name, points, remarks
        })
      }).then(res => {
        onSubmit(res.user.id);
        dispatch(clearUser())
      }).catch(err => {
        dispatch(addError(err))
      });
    } else {
      return api(`${url}/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          coway_id, email, phone_number, password, role, name
        })
      }).then(res => {
        onSubmit(`Edited user ${res.user.coway_id}`);
      }).catch(err => {
        dispatch(addError(err))
      });
    }
  }

  const handleDeleteUser = () => {
    setShowConfirmDelete(false)
    deleteUser()
  }

  return (
    <Modal show={show} onHide={() => onClose()} size="lg">
      <Modal.Header className="mx-2 mt-1 merchant-modal-header" closeButton>
        { isCreate ?
          <h4>Create User</h4>
          :
          <h4>User Detail</h4>
        }
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(submit)} className="d-grid gap-3">
          <Row className="mx-0 justify-content-center">
            <Col lg={8}>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">User Info</Card.Header>
                <Card.Body className="m-2">
                  <div className="d-grid gap-3">
                    <Field className="form-control" required name="coway_id" label="Coway ID (Health Planner/Sales Manager ID)" placeholder="Coway ID" type="text" component={Input} />
                    <Field className="form-control" required name="name" label="Name" placeholder="Name" type="text" component={Input} />
                    <Field className="form-control" name="email" label="Email" placeholder="Email" type="text" component={Input} />
                    <Field className="form-control" name="phone_number" label="Phone Number" placeholder="Phone Number" type="text" component={Input} />
                    <Field className="form-control" name="role" label="Role" type="select" component={Select} options={userRolesOptions} defaultOptionName="Select a user role" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-0 justify-content-center">
            <Col lg={8}>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">
                  <Row className="align-items-center justify-content-between">
                    <Col xs="auto">Points{isCreate ? '' : ' History'}</Col>
                  </Row>
                </Card.Header>
                <Card.Body className="m-2">
                  { isCreate ?
                    <div className="d-grid gap-3">
                      <Field className="form-control" required name="points" label="Points Rewarded" placeholder="Points Rewarded" type="number" component={Input} />
                      <Field className="form-control" required name="remarks" label="Remarks" placeholder="Remarks" component={TextArea} />
                    </div>
                    :
                    <ListGroup>
                      <p>Coming Soon</p>
                      {/* { user.points && user.points.length > 0 ? */}
                      {/*     _.map(_.sortBy(user.points, (point) => { */}
                      {/*       return -moment(point.created).valueOf() */}
                      {/*     }), (point, index) => ( */}
                      {/*       <ListGroup.Item key={index} className="d-flex justify-content-between"> */}
                      {/*         <div className="d-grid gap-1"> */}
                      {/*           <span>{point.value}</span> */}
                      {/*           <span className="small text-secondary">{point.remarks}</span> */}
                      {/*         </div> */}
                      {/*         <div className="small text-secondary"> */}
                      {/*           {moment(point.created).format('DD/MM/YYYY')} */}
                      {/*         </div> */}
                      {/*       </ListGroup.Item> */}
                      {/*     )) */}
                      {/*     : */}
                      {/*       <p>No points earned yet</p> */}
                      {/* } */}
                    </ListGroup>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mx-2 mt-4 d-grid gap-2">
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}
            >
              {isCreate ?
                <>{submitting ? 'SUBMITTING' : 'SUBMIT'}</>
                :
                <>{submitting ? 'UPDATING' : 'UPDATE'}</>
              }

            </Button>
            { !isCreate &&
            <Button
              variant="danger-secondary"
              disabled={submitting}
              onClick={() => setShowConfirmDelete(true)}
            >
              DELETE
            </Button>
            }
          </Row>
        </form>
      </Modal.Body>
      <ConfirmModal contentClass="border" title="Delete User" onClose={() => setShowConfirmDelete(false)} onClickYes={handleDeleteUser} show={showConfirmDelete}>
        <p>Confirm delete this user? This action cannot be undone!</p>
      </ConfirmModal>
    </Modal>
  )
}

User = reduxForm({
  form: 'user',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(User)

const selector = formValueSelector('user')

export default connect((state, props) => {
  const { isCreate } = props
  const roleValue = selector(state, 'role')

  return {
    roleValue,
    initialValues: isCreate ? {} : state.user.item
  };
}, (dispatch) => {
  return bindActionCreators({ change }, dispatch);
})(User);
