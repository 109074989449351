import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import qs from 'qs';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { Row, Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Loader from '../components/Loader';

import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';

const Receipt = () => {
  const [receiptState, setReceiptState] = useState({
    files: null,
    loading: false,
  })

  const [orderName, setOrderName] = useState(null)

  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchReceipts();
  }, [])

  const fetchReceipts = async () => {

    const { id, name, links } = qs.parse(decodeURIComponent(search.slice(1)));

    setReceiptState({ files: null, loading: true });

    if(id && name){
      setOrderName(name)

      return api(`/receipt?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        setReceiptState({
          loading: false,
          files: result || null
        });
      }).catch(err => {
        setReceiptState({ files: null, loading: false });
        if (err) {
          dispatch(addError(err))
        }
      });
    } else if (links){
      let files = links.split(',')

      setReceiptState({
        loading: false,
        files: _.map(files, file => {
          return {
            link: file
          }
        })
      });
    }
  }

  const downloadFile = (file) => {
    saveAs(file.link, file.filename)
  }

  const { loading, files } = receiptState

  return (
    <div className='container'>
      <h1>Sales Receipt(s): {orderName}</h1>
      {
        loading &&
          <Loader />
      }
      {
        !loading && files &&
          <div>
            {
              files.map((file, i) => {
                return (
                  <>
                    <div className='text-center my-3' key={i}>
                      <p>
                        {file.filename}{" "}
                        <Button onClick={() => downloadFile(file)}>
                          Download{" "}
                          <FontAwesomeIcon icon={solid('download')}/>
                        </Button>
                      </p>
                      <Document className="overflow-scroll" error='Failed to load/not PDF file' file={file.link}>
                        <Page style={{width: '50%'}} className="d-flex justify-content-lg-center" pageNumber={1} scale={1.5}/>
                      </Document>
                    </div>
                    <hr style={{margin: '5px', color:'#707070'}} />
                  </>
                )
              })
            }
          </div>
      }
    </div>
  );
}

export default Receipt;
