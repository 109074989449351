import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';

const Select = ({ input, label, name, type, options, onChangeValue, defaultOptionName, isRenderLabel = true, meta: { touched, error }, inline, noFormGroup }) => (
  <div className={`${!noFormGroup && 'form-group'} ${touched && error && "has-error has-feedback"}`}>
    {
      isRenderLabel &&
      <label htmlFor={name}>{label}</label>
    }
    <select style={inline && {display: 'inline', width: 'unset'}} className="form-control" {...input} onChange={(e) => {
      input.onChange(e);
      if (onChangeValue) {
        onChangeValue(e);
      }
    }}>
      {
        defaultOptionName &&
        <option value="">{defaultOptionName}</option>
      }
      {
        options.map(option => (
          <option key={option.key} value={option.value}>{option.name}</option>
        ))
      }
    </select>
    {
      touched && error &&
        <span style={{color: '#d15d5d'}}>
        <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '12px'}}/>
        {error}
      </span>
    }
  </div>
)

export default Select;
