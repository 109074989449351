import { createSlice } from '@reduxjs/toolkit';
import { login } from './auth';
import { listOrders } from './orders';
import { fetchManualOrderDetail } from './manualOrderDetail';
import _ from 'lodash';

const initialState = {
  error: false,
  errorMsg: ''
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    addError: (state, action) => {
      const error = action.payload
      state.error = true;

      if (error && error.message) {
        state.errorMsg = error.message
      } else if (typeof error === 'string') {
        state.errorMsg = error
      } else if (_.isArray(error)) {
        state.errorMsg = error.join('\n')
      } else if (error.validationError) {
        state.errorMsg = error.validationError.join('\n')
      } else {
        state.errorMsg = 'Something bad happened'
      }
    },
    resetError: (state) => {
      state.error =  false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.rejected, (state, action) => {
        state.error = true;
        state.errorMsg = action.payload
      })
      .addCase(login.fulfilled, (state, action) => {
        state.error = false;
      })
      .addCase(listOrders.rejected, (state, action) => {
        if(action.payload){
          state.error = true;
          state.errorMsg = action.payload
        }
      })
      .addCase(fetchManualOrderDetail.rejected, (state, action) => {
        if(action.payload){
          state.error = true;
          state.errorMsg = action.payload
        }
      })
  }
})

export const { addError, resetError } = errorSlice.actions

export default errorSlice.reducer
