import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { logout } from '../redux/slices/auth';

import api from '../helpers/apiClient';
import config from '../config';

const localStorageKey = `${config.storefrontAccessToken}.${config.shopifyDomain}.checkoutId`

const Logout = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    saveCheckoutAndLogout()
  }, [])

  const saveCheckoutAndLogout = async () => {
    api('/user/save_checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ checkout_id: localStorage.getItem(localStorageKey) })
    }).finally(() => {
      localStorage.removeItem(localStorageKey)
    })

    dispatch(logout())
  }

  return null;
}

export default Logout
