import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import "react-dates/lib/css/_datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';

const DatePicker = props => {
  const { input, label, meta: { touched, error }, handleOnChange } = props;
  const [period, setPeriod] = useState({ startDate: null, endDate: null })
  const [focused, setFocused] = useState(null)

  useEffect(() => {
    if(input.value){
      const { startDate, endDate } = JSON.parse(input.value);
      let period = {
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null
      };

      setPeriod(period);
    }
  }, [input])

  const handleOnDatesChange = (value) => {
    handleOnChange(JSON.stringify(value));
  }

  return (
    <div className={`form-group d-grid gap-1 ${touched && error ? "has-error has-feedback" : ""}`}>
      <label>{label}</label>
      <DateRangePicker
        small
        minimumNights={0}
        numberOfMonths={1}
        displayFormat="D/M/YY"
        showClearDates={false}
        showDefaultInputIcon={true}
        isOutsideRange={() => false}
        startDate={period.startDate}
        startDateId="validity-period-start"
        endDate={period.endDate}
        endDateId="validity-period-end"
        onDatesChange={handleOnDatesChange}
        focusedInput={focused}
        onFocusChange={(focusedInput) => setFocused(focusedInput)}
      />
      {
        touched && error &&
          <span style={{color: '#d15d5d'}}>
            <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
            {error}
          </span>
      }
    </div>
  )
}

export default DatePicker;
