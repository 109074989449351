import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';

import { addError } from '../redux/slices/error';

const ChangePasswordModal = ({ onClose, onClickSave }) => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const dispatch = useDispatch()

  const handleOnClickSave = () => {

    if(password !== confirm){
      dispatch(addError("Password is not the same. Please check again."))
    } else {
      onClickSave(password);
    }
  }

  return (
    <Modal style={{top:'15%', border: 'none'}}  show={true} onHide={() => onClose()}>
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4>Change Password</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        <form>
          <div className="form-group">
            <label>Enter new password</label>
            <input type="password" className="form-control" id="password" name="password" onChange={(e) => setPassword(e.target.value)}/><br/>
            <label>Confirm new password</label>
            <input type="password" className="form-control" id="confirm" name="confirm" onChange={(e) => setConfirm(e.target.value)}/>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
        <Button variant="danger-secondary" onClick={() => onClose()}>Cancel</Button>
        <Button variant="primary" onClick={() => handleOnClickSave()}>
          Change password
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePasswordModal;
