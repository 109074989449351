import { Card, Row, Col, Button, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Clipboard from 'clipboard';
import _ from 'lodash';

import AddGiftCardModal from '../components/AddGiftCardModal';
import ConfirmModal from '../components/ConfirmModal';
import TabHeader from '../components/TabHeader';
import api from '../helpers/apiClient';
import { getProfile } from '../redux/slices/user';
import { addError } from '../redux/slices/error';
import denominations from '../data/creditsDenom.json';
import creditsLogo from '../images/credits-logo.svg';
import fpxLogo from '../images/fpx-logo.svg';
import popularLogo from '../images/credits-popular.svg';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const Credits = () => {
  const { item: profile } = useSelector(state => state.user);
  const { user } = useSelector(state => state.auth)
  const isAdmin = user && user.role === 'admin'

  const dispatch = useDispatch();

  const [selectedDenom, setSelectedDenom] = useState({ value: null, amount: '' })
  const [purchases, setPurchases] = useState([])
  const [giftcards, setGiftcards] = useState([])
  const [balance, setBalance] = useState(0)
  const [total, setTotal] = useState(0)
  const [showAddGiftCardModal, setShowAddGiftCardModal] = useState(false)
  const [showConfirmPurchase, setShowConfirmPurchase] = useState(false)

  useEffect(() => {
    dispatch(getProfile())

    getCredits();

    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {
      e.trigger.innerText = 'COPIED'
      e.clearSelection();
      setTimeout(() => {
        e.trigger.innerText = 'COPY'
      }, 5000)
    });
  }, [])

  const getCredits = async () => {
    return api('/credits').then(res => {
      setGiftcards(res.giftcards)
      const balance = _.reduce(res.giftcards, (sum, giftcard) => {
        return sum + giftcard.balance
      }, 0)
      const total = _.reduce(res.giftcards, (sum, giftcard) => {
        return sum + giftcard.initial_value
      }, 0)
      setBalance(balance)
      setTotal(total)
      setPurchases(res.purchases)
    })
  }

  const purchaseCredits = () => {
    const { value, amount } = selectedDenom
    const { name, email } = profile

    api('/purchase/credits', {
      method: 'POST',
      body: JSON.stringify({
        email,
        name,
        amount: value,
        reference_1_label: "Credits Amount",
        reference_1: `${amount}`,
        description: `GFB credits: ${currency}${amount}`,
        type: 'credits'
      })
    })
      .then(result => {
        window.location = result.url;
      })
  }

  const renderAmount = (col, row) => {
    return `${currency} ${col/100}`
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  const renderGiftcard = (row) => {

    const titleStyle = {
      "fontSize": "1.2em",
      "fontWeight": "300",
      "fontFamily": "Varela Round, Arial, sans-serif",
    }

    return (
      <div className="container-fluid" style={{color: '#5A5A5A'}}>
        <Timeline lineStyle={{background: 'none'}}>
          {
            _.map(_.reverse(row.timeline), event => {
              const { subject, order_name, order_id, amount } = event

              return (
                <TimelineEvent
                  key={event.created}
                  createdAt={moment(event.created).format('DD/MM h:mm a')}
                  titleStyle={titleStyle}
                  icon={<FontAwesomeIcon icon={solid('calendar')} transform="grow-4" style={{color: '#31a5e1'}}/>}
                  bubbleStyle={{background: 'none', border: 'none'}}
                >
                  <div className="buyer-speech-bubble" style={{whiteSpace: 'pre-line'}}>
                    {
                      subject === 'creation' &&
                        'A new gift card was issued.'
                    }
                    {
                      subject === 'transaction' &&
                        <span>
                          Paid {currency}{amount.toFixed(2)} with this gift card on order{" "}
                          <a href={`http://business.giftr.my/orders/${order_id}`}>{order_name}</a>
                        </span>
                    }
                  </div>
                </TimelineEvent>
              )
            })
          }
      </Timeline>
      </div>
    )
  }

  const handleOnClickAddGiftCard = () => {
    setShowAddGiftCardModal(true)
  }

  const renderDate = (col) => {
    if(col){
      return moment(col).format('DD/MM/YYYY')
    }
  }

  const renderCode = (col) => {
    return (
      <div className="position-relative" style={{paddingRight: '80px'}}>
        {col}
        <Button onClick={e => e.stopPropagation()} className="copy-to-clipboard py-1 position-absolute" style={{right: '0', width: '80px'}} variant="secondary" data-clipboard-text={col}>COPY</Button>
      </div>
    )
  }

  const giftcardsCol = [
    {
      dataField: 'giftcard_id',
      text: '',
      formatter: () => {
        return <Button variant="info" size="sm" style={{borderRadius: '100%'}}>
          <FontAwesomeIcon icon={solid('chevron-down')} transform="grow-4" style={{color: 'white'}}/>
          </Button>
      },
      headerStyle: {
        width: '36px',
      }
    },
    { dataField: 'created_at', text: 'Created', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
    { dataField: 'purchase_id', text: 'Purchase ID', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'giftcard_id', text: 'ID', hidden: true, headerStyle: { width: '110px', } },
    { dataField: 'email', text: 'Email', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'code', text: 'Gift Card Code', formatter: renderCode, headerStyle: { width: '255px', } },
    { dataField: 'initial_value', text: 'Amount', formatter: (col) => { return `${currency} ${col}` }, headerStyle: { width: '110px', } },
    { dataField: 'expires_on', text: 'Expiry', sort: true, sortFunc: dateSort, formatter: renderDate, headerStyle: { width: '110px', } },
    { dataField: 'balance', text: 'Balance', sort: true, formatter: (col) => { return `${currency} ${col}` }, headerStyle: { width: '110px', } },
  ];

  const purchaseCol = [
    { dataField: 'billplz_id', text: 'ID', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'email', text: 'Email', hidden: !isAdmin, headerStyle: { width: '110px', } },
    { dataField: 'paid_at', text: 'Paid At', formatter: renderDate, sort: true, sortFunc: dateSort, headerStyle: { width: '110px', } },
    { dataField: 'amount', text: 'Amount', formatter: renderAmount, headerStyle: { width: '110px', } },
    { dataField: 'paid_amount', text: 'Paid', formatter: renderAmount, headerStyle: { width: '110px', } },
    {
      dataField: 'paid',
      text: 'State',
      formatter: (col) => {
        if(col){
          return (
            <Badge bg="success text-uppercase">
              Paid
            </Badge>
          )
        } else {
          return (
            <Badge bg="warning text-uppercase">
              Unpaid
            </Badge>
          )
        }
      },
      headerStyle: {
        width: '110px',
      }
    },
    {
      dataField: 'fulfillment_status',
      text: 'Issued',
      formatter: (col) => {
        if(col === 'fulfilled'){
          return <Badge bg="success text-uppercase">yes</Badge>
        } else {
          return <Badge bg="danger text-uppercase">no</Badge>
        }
      },
      headerStyle: {
        width: '110px',
      }
    },
  ];

  const savings = (name, value) => {
    return (
      <>
        {
          name === '1000' &&
            <img className='position-absolute' style={{top: '-10px', left: '-15px'}} height="45" src={popularLogo} />
        }
        {
          (name === '1000' || name === '2000') &&
            <div className='denom-savings d-inline-block'>Save RM{parseInt(name) - (value/100)}</div>
        }
      </>
    )
  }

  return (
    <>
      <TabHeader
        title="My Credits"
        tab_id="purchase_credits"
        children={
          <>
            {
              isAdmin &&
                <Button onClick={handleOnClickAddGiftCard} variant="primary" className="btn-br-6 d-print-none">Add Gift Card</Button>
            }
          </>
        }
      />
      <Row className="mb-3">
        <Col lg={8}>
          <Card className="curve-border shadow-sm mb-3">
            <Card.Body className="p-4">
              <h3 className="p-2">Purchase Giftr Credits</h3>
              <div>
                <Row>
                  <Col xs={12} sm={4} className="pb-2 text-center">
                    <img src={creditsLogo} height="150" style={{maxWidth: '100%'}} className="float-sm-end"/>
                  </Col>
                  <Col xs={12} sm={8} className="pb-2">
                    <h5 style={{textDecoration: 'underline'}}>What is Giftr Credits?</h5>
                    <ul>
                      <li>Suitable for frequent repeated purchases for business, just apply the code during checkout to place order on giftr.my</li>
                      <li>Each code can be used for multiple transactions.</li>
                      <li>Valid for 2 years. Unused balance is not refundable.</li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col sm={9} className="px-3 py-2">
                    <Row>
                      {
                        denominations.map(({ value, name, key }) => {
                          const size = (name === '1000' || name === '2000') ? 6 : 3

                          return (
                            <Col key={key} className='my-1' sm={size}>
                              <Button variant="light" className={`${selectedDenom.value === value ? 'denom-active': ''} w-100 position-relative`} style={{border: '1px solid #dfdfdf'}} onClick={() => {
                                setSelectedDenom({
                                  value,
                                  amount: name
                                })
                              }}>
                                <span className="me-2 d-inline-block">{currency}{name}</span>{savings(name, value)}
                              </Button>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                  <Col sm={3} className="py-2 px-3 text-center" style={{fontSize: '1.2rem'}}>
                    { selectedDenom &&
                      <p>Total - {`RM${(selectedDenom.value/100).toFixed(2)}`}</p>
                    }
                    <Button className="btn-cta w-100" style={{border: '1px solid #dfdfdf', fontSize: '1.2rem'}}
                      onClick={() => {
                        if(!selectedDenom.value && !selectedDenom.amount){
                          dispatch(addError('Please select a denomination'))
                          return
                        }
                        setShowConfirmPurchase(true)
                      }}
                    >
                      Buy Now
                      <img src={fpxLogo} width="60" style={{margin: '0 5px'}}/>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="curve-border shadow-sm mb-3">
            <Card.Body className="p-4">
              <h4>Balance</h4>
              <Row className="my-5">
                <Col sm={12} className="text-center">
                  <p className="h3">{currency}{balance.toFixed(2)}</p>
                </Col>
                <Col sm={12} className="h4 text-center">
                  <p >/ {currency}{total.toFixed(2)}</p>
                </Col>
              </Row>
              <div className="w-100" style={{
                height: '40px',
                borderRadius: '12px',
                overflow: 'hidden',
                position: 'relative'
              }}>
                <div style={{
                  height: '100%',
                  backgroundColor: '#81d8d0',
                  width: `${(balance/total)*100}%`,
                  position: 'absolute'
                }}>
                </div>
                <div className="w-100" style={{
                  height: '100%',
                  border: '2px #81d8d0 solid',
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h4>Gift Cards <span style={{fontSize: '0.6em', color: '#5a5a5a'}}>Click on
        [<FontAwesomeIcon icon={solid('chevron-down')} />]
        sign to view the order history</span></h4>
      <ToolkitProvider
        keyField='giftcard_id'
        data={giftcards}
        columns={giftcardsCol}
      >
        {
          props => (
            <>
              <BootstrapTable
                { ...props.baseProps }
                bordered={ false }
                pagination={giftcards.length > 10 ? paginationFactory({
                    showTotal: true,
                    sizePerPage: 10,
                    sizePerPageList: [{
                      text: '10', value: 10
                    }, {
                      text: '25', value: 25
                    }, {
                      text: '50', value: 50
                    }, {
                      text: 'All', value: giftcards.length
                    }],
                }) : undefined}
                hover
                noDataIndication={() => 'There is no data to display'}
                expandRow={{
                  renderer: renderGiftcard
                }}
                wrapperClasses="mb-3"
                rowClasses="cursor-pointer"
                defaultSorted={[{
                  dataField: 'created_at',
                    order: 'desc',
                }]}
              />
            </>
          )
        }
      </ToolkitProvider>
      <h4>Purchase History</h4>
      <ToolkitProvider
        keyField='billplz_id'
        data={purchases}
        columns={purchaseCol}
      >
        {
          props => (
            <>
              <BootstrapTable
                { ...props.baseProps }
                bordered={ false }
                pagination={purchases.length > 10 ? paginationFactory({
                  showTotal: true,
                  sizePerPage: 10,
                  sizePerPageList: [{
                    text: '10', value: 10
                  }, {
                    text: '25', value: 25
                  }, {
                    text: '50', value: 50
                  }, {
                    text: 'All', value: purchases.length
                  }],
                }) : undefined}
                hover
                noDataIndication={() => 'There is no data to display'}
                wrapperClasses="mb-3"
                defaultSorted={[{
                  dataField: 'paid_at',
                    order: 'desc',
                }]}
              />
            </>
          )
        }
      </ToolkitProvider>
      {
        showAddGiftCardModal &&
          <AddGiftCardModal onSubmit={() => {
            setShowAddGiftCardModal(false);
            getCredits();
          }} onClose={() => setShowAddGiftCardModal(false)}/>
      }
      {
        showConfirmPurchase &&
          <ConfirmModal
            title="Confirm Purchase"
            noBtnTitle="Cancel"
            yesBtnTitle="Confirm"
            onClickYes={() => purchaseCredits()}
            onClose={() => setShowConfirmPurchase(false)}
          >
            <p className="mb-0">Confirm purchase of {currency}{selectedDenom.amount} credits?</p>
            <p className='mb-0'>Total: {
              parseInt(selectedDenom.amount) > (selectedDenom.value/100) ?
                <>
                  <del>{currency}{selectedDenom.amount}</del>
                  {" "}
                </>
                :
                ''
            }{currency}{(selectedDenom.value/100)}</p>
          </ConfirmModal>
      }
    </>
  )
}

export default Credits;
