import { Modal, Button } from 'react-bootstrap'
import { reduxForm, Field, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Transition } from 'react-transition-group';

import GFBAlert from './GFBAlert';
import { fadeTransition } from '../styles/transitions';
import Input from './Form/Input';
import TextArea from './Form/TextArea';
import FileUpload from './Form/FileUpload';
import UserSelection from './UserSelection';
import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';
import DatePicker from './Form/DatePicker';

let CreateDealModal = ({ onClose, handleSubmit, submitting }) => {
  const [created, setCreated] = useState(false);
  const dispatch = useDispatch();

  const submit = async values => {
    const { user, title, reward_info, validity, marketing_kit } = values;
    const { startDate, endDate } = JSON.parse(validity);

    let result = await api('/deal/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user,
        title,
        reward_info,
        start_at: startDate,
        end_at: endDate,
        marketing_kit,
      })
    }).catch(err => {
      dispatch(addError(err.message))
    })

    if(result?.success){
      setCreated(true);
    }

    if(result?.message){
      dispatch(addError(result.message));
    }
  }

  const handleFileUpload = (name, url) => {
    dispatch(change("CreateDeal", name, url));
  }

  return (
    <Modal style={{top:'15%', border: 'none'}} show={true} onHide={() => onClose()} size="md">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <Modal.Title>Create Deal</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <div className="d-grid gap-1">
            <Field className="form-control" required name="user" label="Select User" type="text" component={UserSelection} />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="title" label="Title" placeholder="Title" type="text" component={Input} />
          </div>

          <div className="d-grid gap-1">
            <Field className="form-control" required name="reward_info" label="Reward Info" placeholder="Reward Info" component={TextArea} />
          </div>

          <div className="d-grid gap-1">
            <Field required name="validity" label="Validity" placeholder="Validity" component={DatePicker} handleOnChange={value => dispatch(change("CreateDeal", "validity", value))} />
          </div>

          <div className="d-grid gap-1">
            <Field name="marketing_kit" label="Marketing Comms Kit" component={FileUpload} placeholder="Marketing Comms Kit" handleOnChange={handleFileUpload}/>
          </div>

        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button
            variant="primary"
            type="submit"
            disabled={ submitting || created }
          >
            Submit
          </Button>
          <Transition in={created} timeout={300}>
            {state => (
              <GFBAlert
                style={{
                  opacity: '0',
                  ...fadeTransition[state]
                }}
                variant="success"
                alert="Created"
                dismissible={false}
                handleClose={() => setCreated(false)}
              />
            )}
          </Transition>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

CreateDealModal = reduxForm({
  form: 'CreateDeal',
  destroyOnUnmount: true,
})(CreateDealModal);

export default CreateDealModal;
