import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import qs from 'qs';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Loader from '../components/Loader';

import api from '../helpers/apiClient';
import { addError } from '../redux/slices/error';

const Invoice = () => {
  const [invoiceState, setInvoiceState] = useState({
    files: null,
    loading: false,
  })

  const [orderName, setOrderName] = useState(null)

  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchInvoice();
  }, [])

  const fetchInvoice = async () => {

    const { id, name, link } = qs.parse(decodeURIComponent(search.slice(1)));

    setInvoiceState({ files: null, loading: true });

    if (id && name) {
      setOrderName(name)

      return api(`/invoice?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((result) => {
        setInvoiceState({
          loading: false,
          files: result || null
        });
      }).catch(err => {
        setInvoiceState({ files: null, loading: false });
        if (err) {
          dispatch(addError(err))
        }
      });
    } else if (link) {
      setInvoiceState({
        loading: false,
        files: [{
          filename: link.match(/[\w-]*.\w*$/)[0],
          fileLink: link,
        }]
      });
    }
  }

  const downloadFile = (file) => {
    saveAs(file.fileLink, `Invoice ${file.vendor}.pdf`)
  }

  const { loading, files } = invoiceState

  return (
    <div className="container">
      <h1>Invoice: {orderName}</h1>
      {
        loading &&
          <Loader />
      }
      {
        !loading && files &&
          <div>
            {
              files.map((file, i) => {
                return (
                  <>
                  <div className='text-center my-3' key={i}>
                    <p>
                      {file.vendor}{" "}
                      <Button onClick={() => downloadFile(file)}>
                        Download{" "}
                        <FontAwesomeIcon icon={solid('download')}/>
                    </Button>
                    </p>
                    <Document className="overflow-scroll" error='Failed to load/not PDF file' file={file.fileLink}>
                      <Page className="d-flex justify-content-lg-center" pageNumber={1} scale={1.5}/>
                    </Document>
                  </div>
                    <hr style={{margin: '5px', color:'#707070'}} />
                  </>
                )
              })
            }
          </div>
      }
    </div>
  );
}

export default Invoice;
