import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'

import Input from '../components/Form/Input';

import { addError } from '../redux/slices/error';
import api from '../helpers/apiClient';
import logo from '../images/coway-logo.png';

const validate = values => {
  const errors = {}
  if (!values.coway_id) {
    errors.coway_id = 'Required';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Required';
  } else if(values.confirm_password !== values.password){
    errors.confirm_password = 'Passwords do not match'
  }
  return errors
}

const Signup = ({ handleSubmit, submitting }) => {
  const { error, errorMsg } = useSelector(state => state.error);
  const { login } = useSelector(state => state.form);

  const dispatch = useDispatch();

  const [created, setCreated] = useState(false)

  useEffect(() => {
  }, [created])

  const submit = values => {

    const { email, password, name, coway_id } = values;

    const url = '/user/signup';
    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, password, name, coway_id
      })
    }).then(res => {
      // navigate('/signup/success')
      setCreated(true)
    }).catch(err => {
      if (err && err.validationError) {
        dispatch(addError(err.validationError))
      } else {
        dispatch(addError(err))
      }
    });
  }

  return (
    <div className="login-backdrop vh-100">
      <Row className="flex-column-reverse flex-lg-row w-100 ms-0" id="pwd-container">
        <Col xs={12} className="text-center">
          {
            created ?
              <div className="verification mx-auto shadow-sm">
                <h4 className="mt-3 mb-5" style={{color:'#000'}}>Account Created</h4>
                <p>
                  We have sent you a verification link to your email address{(login && login.values && login.values.email) && <b> {login.values.email} </b>}. Please check your email inbox and verify your email address within 24 hours.
                </p>
              </div>
              :
              <form className="login-form desktop-login d-grid gap-3 mx-auto shadow-sm" onSubmit={handleSubmit(submit)}>
                <img src={logo} width="140" className="mx-auto my-3"/>
                <h4 className="mt-3 mb-5" style={{color:'#000'}}>Sign up to Coway Merchandise</h4>
                {
                  error &&
                    <Alert variant="danger">
                      <div>{errorMsg}</div>
                    </Alert>
                }
                <Field className="form-control" name="coway_id" label="Coway ID/Email" placeholder="Coway ID/Email" type="text" isRenderLabel={false} component={Input} />
                <Field className="form-control" name="name" label="Name" placeholder="Name" type="text" isRenderLabel={false} component={Input} />
                <Field className="form-control" name="email" label="Email" placeholder="Email" type="email" isRenderLabel={false} component={Input} />
                <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" isRenderLabel={false} component={Input} />
                <Field className="form-control" name="confirm_password" label="Confirm password" placeholder="Confirm password" type="password" isRenderLabel={false} component={Input} />
                <Button
                  type="submit"
                  disabled={submitting}
                  className="mt-5"
                >
                  {submitting ? 'SIGNING UP...' : 'SIGN UP'}
                </Button>
                <p className="mb-0">Already have an account? <Link to="/login" style={{textDecoration: 'none', color: '#31a5e1'}}>Login</Link></p>
              </form>
          }
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  form: 'login',
  validate,
  destroyOnUnmount: true,
})(Signup)
