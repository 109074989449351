import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, reset } from 'redux-form';
import { Row, Col, Button, Alert, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { resetError, addError } from '../redux/slices/error';

import Input from '../components/Form/Input';

import api from '../helpers/apiClient';
import logo from '../images/coway-logo.png';

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Required';
  } else if(values.confirm_password !== values.password){
    errors.confirm_password = 'Passwords do not match'
  }
  return errors
}

const Login = ({ handleSubmit, submitting, change }) => {
  const { error, errorMsg } = useSelector(state => state.error);

  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false)
  const [cowayId, setCowayId] = useState('')
  const [validId, setValidId] = useState(false)
  const [emailFound, setEmailFound] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(resetError())
    }
  }, [])

  const submit = values => {

    const url = '/user/first_time_login';
    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...values, coway_id: cowayId})
    }).then(() => {
      setSubmitted(true)
    }).catch(err => {
      if (err && err.validationError) {
        dispatch(addError(err.validationError))
      } else {
        dispatch(addError(err))
      }
      dispatch(reset('first_time_login'))
    })
  }

  const verifyId = async () => {
    return api('/user/verify_id', {
      method: "POST",
      body: JSON.stringify({ cowayId })
    }).then((result) => {
      if (result.has_password) {
        return setSubmitted(true)
      }
      if (result.email) {
        change('email', result.email)
        setEmailFound(true)
      }
      setValidId(true)
      dispatch(resetError())
    }).catch(err => {
      if (err && err.validationError) {
        dispatch(addError(err.validationError))
      } else {
        dispatch(addError(err))
      }
      setCowayId('')
    })
  }

  return (
    <div className="login-backdrop vh-100">
      <Row className="w-100 ms-0 h-100" id="pwd-container">
        <Col xs={12} className="text-center d-flex align-items-center">
          { submitted &&
          <div className="verification mx-auto shadow-sm">
            <h4 className="mt-3 mb-5" style={{color:'#000'}}>Account Created</h4>
            <p>You may now login with your Coway ID/Email and Password</p>
            <Link to="/login" style={{textDecoration: 'none', color: '#31a5e1'}}>Login</Link>
          </div>
          }
          { !submitted &&
            <form className="login-form desktop-login d-grid gap-3 mx-auto shadow-sm" onSubmit={handleSubmit(submit)}>
              <img src={logo} width="240" className="mx-auto mt-5 mb-0"/>
              <p className="text-center mb-0 login-title">Gifts & Rewards Center</p>
              {
                error &&
                  <Alert variant="danger">
                    <div>{errorMsg}</div>
                  </Alert>
              }
              { !validId &&
                <>
                  <div>
                    <label>Coway ID</label>
                    <input className="form-control" name="coway_id" placeholder="Coway ID" type="text" value={cowayId} onChange={(e) => setCowayId(e.target.value)} />
                  </div>
                  <Button
                    onClick={verifyId}
                    className="mt-4"
                  >
                    NEXT
                  </Button>
                </>
              }
              { validId &&
                <>
                  <div>
                    <Badge bg="secondary">Coway ID: {cowayId}</Badge>
                  </div>
                  <Field className="form-control" name="email" label="Email" placeholder="Email" type="email" component={Input} disabled={emailFound}/>
                  <Field className="form-control" name="password" label="Create a password" placeholder="Password" type="password" component={Input} />
                  <Field className="form-control" name="confirm_password" label="Confirm password" placeholder="Confirm password" type="password" isRenderLabel={false} component={Input} />
                  <Button
                    type="submit"
                    disabled={submitting}
                    className="mt-4"
                  >
                    {submitting ? 'SUBMITTING...' : 'SUBMIT'}
                  </Button>
                </>
              }
              <Link to="/login" style={{textDecoration: 'none', color: '#31a5e1'}}>Back</Link>
            </form>
          }
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  form: 'first_time_login',
  validate,
  destroyOnUnmount: true,
})(Login)
