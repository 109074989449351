import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';
import PointsDetailModal from '../components/PointsDetailModal';

import { clientDashboard, getDashbboard } from '../redux/slices/dashboard';
import filterImg from '../images/dashboard-filter.png';
import reportImg from '../images/dashboard-report.png';

const currency = window.location.hostname === 'coway.giftr.my' ? 'RM' : 'S$'

const eventStyle = {
  "backgroundColor": "#fff",
  "boxShadow": 'none',
  "borderRadius": "18px",
  "lineHeight": "1.6",
  "fontSize": "16px",
  "fontWeight": "400",
  "fontFamily": "Varela Round, sans-serif",
  "padding": "1.5em 1em",
}

const Dashboard = () => {
  const { item, loading, loaded } = useSelector(state => state.dashboard)
  const { user } = useSelector(state => state.auth)
  const [annCount, setAnnCount] = useState(3)
  const [showPointsDetail, setShowPointsDetail] = useState(false)
  const [cowayID, setCowayID] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if(user && user.role === 'agent'){
      dispatch(clientDashboard())
    }
  }, [])

  const handleSearchUser = () => {
    dispatch(getDashbboard(cowayID));
  }

  return (
    <>
      <TabHeader
        title="Dashboard"
        tab_id="dashboard"
        children={
          <>
            {
              user && user.role === 'admin' &&
                <div className="d-flex flex-row align-items-end">
                  <div className="me-2">
                    <label>Coway ID</label>
                    <input type="text" className="form-control" id="orderId" name="orderId" placeholder="Coway ID" value={cowayID} onChange={e => setCowayID(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') handleSearchUser()}}/>
                  </div>
                  <div className="search-order-btn">
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={handleSearchUser}
                    >
                      Search Coway ID
                    </Button>
                  </div>
                </div>
            }
          </>
        }
      />
      {
        loading &&
          <Loader/>
      }
      {
        !loading && item &&
          <>
            <div className='d-grid gap-4 label-strong'>
              <Row>
                <Col sm={6} className="mb-2">
                  <Card className="curve-border shadow-sm p-2">
                    <Card.Body>
                      <h4 className="mb-0" style={{color: '#31a5e1'}}>{item.name}</h4>
                      <p className="d-print-none my-3">{item.created_at ? `Customer for ${moment.duration(moment().diff(moment(item.created_at))).humanize()}` : ""}</p>
                      {
                        item.orders &&
                          <Row style={{textAlign: 'center'}}>
                            {
                              item.orders.length > 0 &&
                                <Col sm={6}>
                                  <label>Last Order</label><br/>
                                  <Link to={{pathname: `/${item.orders[0].manual_order ? 'manual_orders' : 'orders'}/${item.last_order_id}`, state: {modal: false}}}>
                                    {item.last_order_name}
                                  </Link>
                                </Col>
                            }
                            <Col sm={6}>
                              <label>Total Spent</label><br/>
                              {currency} {item.total_spent.toFixed(2)}<br/>
                              {item.orders_count} order{item.orders_count > 1 ? 's' : ''}
                            </Col>
                          </Row>
                      }
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 main-vr"></div>
                <Col>
                  <Card className="curve-border shadow-sm mb-3">
                    <Card.Body className="p-4">
                      <h4 className="d-flex justify-content-between">
                        <span>Points</span>
                        <FontAwesomeIcon icon={solid('info-circle')} style={{fontSize: '22px'}} className="cursor-pointer" onClick={() => setShowPointsDetail(true)}/>
                      </h4>
                      <Row className="my-5">
                        <Col sm={12} className="text-center">
                          <p className="h3"><FontAwesomeIcon icon={solid('coins')} /> {_.reduce(item.points, (sum, point) => { return sum + point.balance }, 0)}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>

            <PointsDetailModal
              show={showPointsDetail}
              onHide={() => setShowPointsDetail(false)}
              points={item.points}
              history={_.filter(item.logs, log => log.type.includes('points'))}
            />
          </>
      }
      <img src={filterImg} />
      <img src={reportImg} />
      {
        !loading && item &&
          <div className="row my-5">
            <h2 className="sub-header">Announcements</h2>
            <Timeline lineStyle={{ background: 'none' }} style={{ margin: '0', width: '100%' }}>
              {
                _.slice(item.announcements, 0, annCount).map(event => (
                  <TimelineEvent
                    key={event._id}
                    title={event.title}
                    createdAt={moment(event.created).format('ddd DD/MM/YYYY h.mmA')}
                    icon={<FontAwesomeIcon icon={solid('bullhorn')} transform="grow-4" style={{ color: '#31a5e1' }} />}
                    bubbleStyle={{ background: 'none', border: 'none' }}
                    contentStyle={eventStyle}
                  >
                    <div className="timeline_desc" dangerouslySetInnerHTML={{ __html: event.desc }} />
                    {event.file_url && event.is_image &&
                    <img className="img-responsive" src={event.file_url} />
                    }
                    {event.file_url && !event.is_image &&
                      <p>
                        Attached File: <a target="_blank" href={event.file_url}>Download file</a>
                      </p>
                    }
                  </TimelineEvent>
                ))
              }
              <div className="d-flex justify-content-center">
                {
                  annCount < item.announcements.length &&
                    <Button
                      variant="primary"
                      onClick={() => {
                        setAnnCount(annCount + 3)
                      }}
                    >Show More
                    </Button>
                }
              </div>
            </Timeline>
          </div>
      }
    </>
  )
}

export default Dashboard;
