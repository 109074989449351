import { Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

const Verify = () => {
  const { verified } = useParams();

  return (
    <div className="login-backdrop vh-100">
      <Row className="flex-column-reverse flex-lg-row w-100 ms-0 mt-5" id="pwd-container">
        <Col xs={12} className="text-center">
          <div className="verification mx-auto shadow-sm">
          {
            verified === 'true' ?
              <>
                <h4 className="mt-3 mb-5" style={{color:'#000'}}>Verification Successful</h4>
                <p>
                  <Link to={'/login'} className="btn btn-success btn-lg">Login</Link>
                </p>
              </>
              :
              <>
                <h4 className="mt-3 mb-5" style={{color:'#000'}}>Invalid verification code</h4>
                <p>
                  Code has expired or is invalid. Please contact your account manager to create a new account.
                </p>
              </>
          }
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Verify;
