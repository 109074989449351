import { useEffect } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment';

import Loader from '../components/Loader';
import { listLogs } from '../redux/slices/logs';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const Logs = () => {
  const { items, loaded, loading } = useSelector(state => state.logs);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!loaded){
      dispatch(listLogs());
    }
  }, [])

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (!date2) {
      date2 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  const renderType = (cell, row) => {
    switch (cell) {
      case 'user_created':
        return <Badge bg="secondary">User Created</Badge>;
      case 'points_rewarded':
        return <Badge bg="success">Points Rewarded</Badge>;
      case 'points_redeemed':
        return <Badge bg="warning">Points Redeemed</Badge>;
      default:
        return ''
    }
  }

  const logsCol = [
    { dataField: 'id', text: '', hidden: true, },
    {
      dataField: 'created',
      text: 'Date',
      sort: true,
      formatter: (cell) => {
        return moment(cell).format('YYYY-MM-DD h:mm a')
      },
      headerStyle: {
        width: '100px',
        resize:'horizontal'
      },
      sortFunc: dateSort
    },
    { dataField: 'type', text: 'Event', headerStyle: { width: '100px', }, formatter: renderType },
    { dataField: 'user', text: 'User', headerStyle: { width: '100px', }, },
    { dataField: 'points', text: 'Points', headerStyle: { width: '100px', }, formatter: (cell, row) => {
      if (row.type === 'points_rewarded') {
        return '+' + cell
      } else if (row.type === 'points_redeemed') {
        return '-' + cell
      }
    }},
    { dataField: 'remarks', text: 'Remarks', headerStyle: { width: '100px', }, },
  ]

  return (
    <>
      <Row className="mt-3 mb-4">
        <Col xs={12} sm={5}>
          <h2 className="sub-header">Activity Log</h2>
        </Col>
      </Row>
      {
        loading &&
          <Loader />
      }
      {
        !loading &&
        <ToolkitProvider
          keyField='id'
          data={items}
          columns={logsCol}
          search
        >
          {
            props => (
              <>
                <Row className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                  <Col>
                    <ExportCSVButton
                      className="btn-info"
                      style={{ borderRadius: '8px' }}
                      {...props.csvProps}
                    ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{ marginRight: '15px' }} />
                      Export to CSV</ExportCSVButton>
                  </Col>
                  <Col xs={4} className='search-bar-full-width'>
                    <SearchBar {...props.searchProps}
                      style={{ borderRadius: '25px' }} placeholder="Search" />
                  </Col>
                </Row>
                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPage: 50,
                    sizePerPageList: [{
                      text: '10', value: 10
                    }, {
                      text: '25', value: 25
                    }, {
                      text: '50', value: 50
                    }, {
                      text: 'All', value: items.length
                    }],
                  })}
                  hover
                  noDataIndication={() => 'There is no data to display'}
                  defaultSorted={[{
                    dataField: 'created',
                    order: 'desc',
                  }]}
                />
              </>
            )
          }
        </ToolkitProvider>
      }
    </>
  )
}

export default Logs;
